// Date Functions
import { parseISO, formatDistance, formatDuration } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz'
import { isNumeric } from './generalFunctions';


export const getDaysBetweenDates = (d1, d2) =>
{

    // Convert both dates to milliseconds
  let date1Ms = new Date(d1).getTime();
  let date2Ms = new Date(d2).getTime();

  // Calculate the difference in milliseconds
  let differenceMs = date2Ms - date1Ms;

  // Convert back to days
  let differenceDays = differenceMs / (1000 * 60 * 60 * 24);

  return differenceDays;

}

export const nowInIndia = (includeTime = false)=>
{
  return(formatToIndiaTime((new Date()).toISOString(), includeTime))
}


export const formatToIndiaTime = (str, includeTime = false) =>
{
      // Parse the input date string to a Date object
    let parsedDate = null
    if(isNumeric(str))
        parsedDate = new Date(str)
    else
        parsedDate = parseISO(str);

    // Format the date in the desired output format
    let format = "yyyy-MM-d"
    if(includeTime)
      format = "yyyy-MM-d HH:mm:ss"

    const formattedDate = formatInTimeZone(parsedDate, 'Asia/Kolkata', format);

    return(formattedDate)
}

export const formatDistanceShort = (str) => {
  
  const date = new Date(str);
  
  if(isNaN(date.getTime()))
    return("Never")


  let response = formatDistance(date, new Date(), { addSuffix: true })

  response = response.replace("about ","")

  return response
}

export const formatDistanceGivenDays = (days, includeWording = true) =>
{
  let sign = Math.sign(days)
  
  days = Math.abs(days)

  let decimalPart = days - Math.floor(days)
  let hours = Math.floor(decimalPart*24)
  let minutes = Math.floor(decimalPart*(24*60))
  let seconds = Math.floor(decimalPart*(24*60*60))

  let response = ""
  if(days >= 1)
    response = formatDuration({days : Math.floor(days) })
  else if(hours >=1)
    response = formatDuration({hours : hours})
  else if(minutes >=1)
    response = formatDuration({hours : minutes})    
  else
    response = formatDuration({seconds : Math.max(1,seconds)})

  if(!includeWording)
    return response

  if( sign >= 0)
    return `In ${response}`
  else
    return `${response} ago`


}