// Global Constants
import config from "./config.json"

export const CONFIG = config

export const STORE = "STORE"

export const DEBUG = false
export const PASSWORD = "sewa@1972"

export const TARGET_PARTICIPANTS = 100

// Store Actions
export const SET_VALUE = "SET_VALUE"
export const UPDATE_OBJECT_VALUE = "UPDATE_OBJECT_VALUE"
export const RESET_ALL_DATASETS = "RESET_ALL_DATASETS"

// Store Values
// Variables
export const LOGGED_IN = "LOGGED_IN"
export const DOWNLOADING_PARTICIPANTS = "DOWNLOADING_PARTICIPANTS"
export const SELECTED_PARTICIPANT = "SELECTED_PARTICIPANT"

// Status
export const STATUS_DATASET_PARTICIPANTS = "STATUS_DATASET_PARTICIPANTS"
export const STATUS_DATASET_SENSORS = "STATUS_DATASET_SENSORS"
export const STATUS_DATASET_HOUSES = "STATUS_DATASET_HOUSES"
export const STATUS_DATASET_PARTICIPANT_PLACEMENT = "STATUS_DATASET_PARTICIPANT_PLACEMENT"
export const STATUS_DATASET_SENSOR_HOUSE_PLACEMENT = "STATUS_DATASET_SENSOR_HOUSE_PLACEMENT"
export const STATUS_DATASET_WEARABLES = "STATUS_DATASET_WEARABLES"
export const STATUS_DATASET_PHONES = "STATUS_DATASET_PHONES"
export const STATUS_DATASET_WEARABLE_PLACEMENT = "STATUS_DATASET_WEARABLE_PLACEMENT"
export const STATUS_DATASET_PHONE_PLACEMENT = "STATUS_DATASET_PHONE_PLACEMENT"
export const STATUS_DATASET_RECEIVED_SENSOR_FILES = "STATUS_DATASET_RECEIVED_SENSOR_FILES"
export const STATUS_DATASET_ISSUES = "STATUS_DATASET_ISSUES"






// Datasets
export const DATASET_PARTICIPANTS = "DATASET_PARTICIPANTS"
export const DATASET_SENSORS = "DATASET_SENSORS"
export const DATASET_HOUSES = "DATASET_HOUSES"
export const DATASET_WEARABLES = "DATASET_WEARABLES"
export const DATASET_PHONES = "DATASET_PHONES"
export const DATASET_PARTICIPANT_PLACEMENT = "DATASET_PARTICIPANT_PLACEMENT"
export const DATASET_WEARABLE_PLACEMENT = "DATASET_WEARABLE_PLACEMENT"
export const DATASET_PHONE_PLACEMENT = "DATASET_PHONE_PLACEMENT"
export const DATASET_SENSOR_HOUSE_PLACEMENT = "DATASET_SENSOR_HOUSE_PLACEMENT"
export const DATASET_RECEIVED_SENSOR_FILES = "DATASET_RECEIVED_SENSOR_FILES"
export const DATASET_ISSUES = "DATASET_ISSUES"






// Pages
export const PAGE_START = "/"
export const PAGE_DASHBOARD_EXPIRY_PARTICIPANTS = "/expiry_participants"
export const PAGE_DASHBOARD_DEPLOYMENT_STATUS = "/deployment_status"
export const PAGE_DASHBOARD_COLLECTION_STATUS = "/collection_status"
export const PAGE_DASHBOARD_DEPLOYMENT_PROGRESS = "/deployment_progress"
export const PAGE_DASHBOARD_DATA_STREAM_QUALITY = "/data_stream_quality"
export const PAGE_DASHBOARD_SINGLE_COLLECTION_STATUS = "/single_collection_status"




// General Contants
export const ID = "id"
export const VALUE = "value"
export const NOT_STARTED = "not_started"
export const ERROR = "error"
export const LOADING = "loading"
export const OK = "ok"
export const NOT_FOUND = "not_found"
export const KEY = "key"
export const DATE = "date"
export const YES = "Yes"
export const NO = "No"


// Errors
export const NETWORK_ERROR = "network_error"



// AirTable Constants
export const AT_API_KEY = "airtable_key"
export const AT_APP_ID = "at_app_id"
export const AT_ACTIVATION_CODE = "Activation Code"
export const AT_DATE_CREATED = "Date Created"
export const AT_SERIAL = "Serial"
export const AT_PUBLIC_ID = "ID"
export const AT_AREA = "Area"
export const AT_SENSOR = "Sensor"
export const AT_PHONE = "Phone"
export const AT_WEARABLE = "Wearable"
export const AT_LOCATION = "Location"
export const AT_LATITUDE = "Latitude"
export const AT_LONGITUDE = "Longitude"
export const AT_ACCURACY = "Accuracy"
export const AT_LAST_UPLOADED = "Last Uploaded"
export const AT_LAST_MIGRATION = "Last Migration"
export const AT_LATEST_RECORD = "Latest Record"
export const AT_BRAND = "Brand"
export const AT_MODEL = "Model"
export const AT_UPLOADED_DATE = "Date Uploaded"
export const AT_START_DATE = "Start Date"
export const AT_END_DATE = "End Date"
export const AT_TAG_COLOR = "Tag Color"
export const AT_TAG_CODE = "Tag Code"
export const AT_SEWA_ID = "SEWA Id"
export const AT_TYPE = "Type"
export const AT_SENSOR_PLACEMENTS = "Sensor Placements"
export const AT_PHONE_PLACEMENTS = "Phone Placement"
export const AT_WEARABLE_PLACEMENT = "Wearable Placement"
export const AT_HAS_ACTIVE_SENSORS = "Has Active Sensors"
export const AT_ACTIVE_SENSORS = "Active Sensors"
export const AT_TOTAL_ACTIVE_SENSORS = "Total Active Sensors"
export const AT_HAS_ACTIVE_WEARABLES = "Has Active Wearables"
export const AT_ACTIVE_WEARABLE = "Active Wearable"
export const AT_HAS_ACTIVE_PHONES = "Has Active Phones"
export const AT_ACTIVE_PHONE = "Active Phone"
export const AT_RECEIVED_SENSOR_FILES = "Received Sensor Files"
export const AT_NAME = "Name"
export const AT_LAST_NAME = "Last Name"
export const AT_PHONE_NUMBER = "Phone Number"
export const AT_NOTES = "Notes"
export const AT_DAYS_UNTIL_EXPIRE = "Days Until Expire"
export const AT_DAYS_SINCE_LAST_UPLOAD = "Days Since Last Upload"
export const AT_LATEST_COLLECTION_DATE = "Latest Collection Date"
export const AT_DATA_START = "Data Start"
export const AT_ACTIVE = "Active"
export const AT_JOINED_DATE = "Joined Date"
export const AT_DATE_UPLOADED = "Date Uploaded"
export const AT_SIZE_IN_DAYS = "Size in Days"
export const AT_FITBIT_TOKEN = "Fitbit Token"
export const AT_FITBIT_REQUEST_ID = "Fitbit Request ID"
export const AT_EMAIL = "Email"
export const AT_ACCOUNT_EMAIL = "Acount Email"
export const AT_DEVICE_COLLECTION_STATUS = "Device Collection Status"
export const AT_DEVICE_COLLECTION_MESSAGE = "Device Collection Message"
export const AT_LATEST_BATTERY_CHANGE = "Latest Battery Change"
export const AT_STATUS = "Status"
export const AT_PENDING = "Pending"
export const AT_DONE = "Done"
export const AT_ISSUES = "Issues"
export const AT_RESPONSIBLE = "Responsible"
export const AT_GROUND_TEAM_ISSUES = "Ground Team Issues"
export const AT_GROUND_TEAM = "Ground Team"
export const AT_NUMERIC_ID = "Numeric ID"
export const AT_TAGS = "Tags"
export const AT_DESCRIPTION = "Description"
export const AT_ACTION = "Action"
export const AT_DATE_SOLVED = "Date Solved"
export const AT_INTAKE_STATUS = "Intake Status"
export const AT_REQUIRED = "Required"
export const AT_COMPLETE = "Complete"
export const AT_INTAKE_SURVEY = "Intake Survey"











// Status and Messages
export const AT_SENSORS_COLLECTION_STATUS = "Sensors Collection Status"
export const AT_SENSORS_COLLECTION_MESSAGE = "Sensors Collection Message"
export const AT_COLLECTION_STATUS = "Collection Status"
export const AT_COLLECTION_MESSAGE = "Collection Message"











// Airtable Tables
export const AT_TABLE_GOVEE_ACTIVATION_CODES_ID = "at_table_govee_activation_codes_id"
export const AT_TABLE_EMAILS = "at_table_emails"
export const AT_TABLE_PARTICIPANTS = "at_table_participants"
export const AT_TABLE_PHONES = "at_table_phones"
export const AT_TABLE_PHONE_PLACEMENTS = "at_phone_placements"
export const AT_TABLE_WEARABLE_PLACEMENTS = "at_wearable_placements"
export const AT_TABLE_WEARABLES = "at_wearables"
export const AT_TABLE_HOUSES = "at_houses"
export const AT_TABLE_PARTICIPANTS_PLACEMENT = "at_participant_placement"
export const AT_TABLE_SENSORS = "at_sensors"
export const AT_TABLE_SENSOR_HOUSE_PLACEMENT = "at_sensor_house_placement"
export const AT_TABLE_SENSOR_PLACEMENT_FORM = "at_sensor_placement_form"
export const AT_TABLE_RECEIVED_SENSOR_FILES = "at_received_sensor_files"
export const AT_TABLE_ISSUES = "at_issues"


// Views
export const AT_VIEW_PARTICIPANTS_PUBLIC = "Public"

// Brands
export const GOVEE = "Govee"
export const HOBO = "Hobo"
export const KESTREL = "Kestrel"

//models
export const U12_HOBO_MODEL = "U12-013"

// Chart Constants
export const PARTICIPANTS = "participants"

// Summary Constants
export const START_DATE = "start_date"
export const END_DATE = "end_date"
export const DURATION = "duration"
export const COVERAGE = "coverage"


// Data Stream Quality Constants
export const SUMMARY_COVERAGE_INTERVALS = "coverage_intervals"
export const SUMMARY_ACCURACY_INTERVALS = "accuracy_intervals"
export const SUMMARY_HEART_RATE = "heart_rate"
export const SUMMARY_TEMPERATURE = "temperature"
export const SUMMARY_GEOLOCATION = "geolocation"
export const SUMMARY_SLEEP = "sleep"

// Devices
export const DEVICE_SENSORS = "Sensors"
export const DEVICE_PHONE = "Location Tracker"
export const DEVICE_WEARABLE = "Wearable"


// Device Status
export const DEVICE_STATUS_OK = "DEVICE_STATUS_OK"
export const DEVICE_STATUS_WAITING_COLLECTION = "DEVICE_STATUS_WAITING_COLLECTION"
export const DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA = "DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA"
export const DEVICE_STATUS_ERROR_IN_COLLECTION = "DEVICE_STATUS_ERROR_IN_COLLECTION"
export const DEVICE_STATUS_HARDWARE_MALFUNCTION = "DEVICE_STATUS_HARDWARE_MALFUNCTION"
export const DEVICE_STATUS_OTHER = "DEVICE_STATUS_OTHER"



// Messages
export const MESSAGE_DATA_UP_TO_DATE = "Data up to date"
export const MESSAGE_PLACEMENT_JUST_STARTED = "Data up to date (Placement just started, no data expected yet)"
export const MESSAGE_BATTERY_CHANGE_JUST_HAPPENED = "Data up to date (Batteries recently updated, no extensive data expected yet)"
export const MESSAGE_WAITING_FOR_COLLECTION = "Waiting for Collection"
export const MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA = "Waiting for Collection (Loosing Data)"
export const MESSAGE_DATA_WAS_NOT_COLLECTED = "Data was not Collected"
export const MESSAGE_NO_FILES_HAVE_BEEN_RECEIVED = "No files have been received"
export const MESSAGE_SENSOR_FILE_NOT_RECENT_ENOUGH = "Latest files not recent enough, please select a more recent end date for the export, or make sure the sensor has synched completely."
export const MESSAGE_SENSOR_FILE_NOT_LONG_ENOUGH = "Latest files not long enough, place select an earlier start date"
export const MESSAGE_NO_SENSORS_IN_BUNDLE = "No sensors in bundle."
export const MESSAGE_INCOMPLETE_DEPLOYMENT = "Incomplete Deployment (missing devices)"
export const MESSAGE_ERROR_IN_COLLECTION = "Error in collection"
export const MESSAGE_HANDLED_BY_CEPT = "Collection is Handled by the CEPT Team"
export const MESSAGE_MULTIPLE_INTAKES = "The participant has multiple active placements."
export const MESSAGE_NO_INTAKES = "The participant has no active placements."
export const MESSAGE_INTAKE_COMPLETE = "Intake survey up to date."
export const MESSAGE_INTAKE_WAITING = "Please administer the intake survey."



// Tag Colors

export const TAG_COLORS = ["gold", "magenta", "blue", "lime", "volcano", "geekblue", "red", "green", "cyan", "orange", "purple"]

