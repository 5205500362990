// Multiple Bar Chart Class (based on D3)

/**
 * Data must be passed with the following structure
 * Array were each object has:
 *    sewa_id : str
      last_name : str
      name : str
      phones : dict
      wearables : dict
      sensors : dict


 */


 import * as d3 from "d3";

import "../Charts.css"
import * as cc from '../ChartConstants';
import { GenericChart } from "./GenericChart";
import * as con from "../../../Constants"


const DEFAULT_PARAMETERS = {
    [cc.MARGIN] : { top: 40, right: 20, bottom: 5, left: 70 },
    [cc.PROPORTIONAL_HEIGHT] : 2500,
    [cc.PROPORTIONAL_WIDTH] : 1000
    
}


 // Local Constants
 const PARTICIPANTS = "participants" 
 const HEART_RATE = "heart_rate"
 const GEOLOCATION = "geolocation"
 const TEMPERATURE = "temperature"
 const SLEEP = "sleep"
 const DEVICES = "devices"
 const STREAM_TYPE = "device_type"
 const DEVICE_ID = "device_id"
 const NAME = "name"
 const LAST_NAME = "last_name"
 const SEWA_ID = "sewa_id"

 
export class StreamQualityChart extends GenericChart
 {

    constructor(parameters, objectReference){
        
        super({...DEFAULT_PARAMETERS, ...parameters}, objectReference)


    }

    
    // Overwrite
    // -------------------------
    build(initialData)
    {
        super.build(initialData)

        // Extracts extra information
        const [maxY, streams] = extractStreams(initialData)
        
        this.barHeight = (this.height / maxY)*0.9

        this.streams = streams
        
        // Updates the scales
        this.xScale = d3.scaleTime()
                 .domain([new Date("2024-03-28"), new Date()])
                 .range([0, this.width])

        

        this.yScale = d3.scaleLinear().range([this.height, 0])
                .domain([0,maxY]);






        // Adds Heart Rate
        this.heart_rate = this.mainComponent.selectAll(".wearableBars")
                            .data(this.streams[HEART_RATE])
                            .enter().append("rect")
                            .attr("class", "wearableBars")
                            .attr("fill",  "var(--primary-color-1)")
                            .style("opacity", (d,i) => d[con.COVERAGE] ? 1 : 0.1 )
                            .attr("x", (d,i) => this.xScale(new Date(d[con.START_DATE])))
                            .attr("width", (d) => this.xScale(new Date(d[con.END_DATE])) - this.xScale(new Date(d[con.START_DATE]))) 
                            .attr("y", (d) => this.yScale(d[cc.Y_VALUE]))
                            .attr("height", this.barHeight)

        // Adds Geolocation
        this.geolocation = this.mainComponent.selectAll(".phoneBars")
                            .data(this.streams[GEOLOCATION])
                            .enter().append("rect")
                            .attr("class", "phoneBars")
                            .attr("fill",  "var(--primary-color-2)")
                            .style("opacity", (d,i) => d[con.COVERAGE] ? 1 : 0.1 )
                            .attr("x", (d,i) => this.xScale(new Date(d[con.START_DATE])))
                            .attr("width", (d) => this.xScale(new Date(d[con.END_DATE])) - this.xScale(new Date(d[con.START_DATE]))) 
                            .attr("y", (d) => this.yScale(d[cc.Y_VALUE]))
                            .attr("height", this.barHeight)   
                            
        // Adds Temperature
        this.temperature = this.mainComponent.selectAll(".sensorBars")
                            .data(this.streams[TEMPERATURE])
                            .enter().append("rect")
                            .attr("class", "sensorBars")
                            .attr("fill",  "var(--primary-color-3)")
                            .style("opacity", (d,i) => d[con.COVERAGE] ? 1 : 0.1 )
                            .attr("x", (d,i) => this.xScale(new Date(d[con.START_DATE])))
                            .attr("width", (d) => this.xScale(new Date(d[con.END_DATE])) - this.xScale(new Date(d[con.START_DATE]))) 
                            .attr("y", (d) => this.yScale(d[cc.Y_VALUE]))
                            .attr("height", this.barHeight) 
                            
        // Adds Sleep
        this.sleep = this.mainComponent.selectAll(".sleepBars")
                            .data(this.streams[SLEEP])
                            .enter().append("rect")
                            .attr("class", "sleepBars")
                            .attr("fill",  "var(--primary-color-5)")
                            .style("opacity", (d,i) => d[con.COVERAGE] ? 1 : 0.1 )
                            .attr("x", (d,i) => this.xScale(new Date(d[con.START_DATE])))
                            .attr("width", (d) => this.xScale(new Date(d[con.END_DATE])) - this.xScale(new Date(d[con.START_DATE]))) 
                            .attr("y", (d) => this.yScale(d[cc.Y_VALUE]))
                            .attr("height", this.barHeight)                                  

        // Adds participant
        this.participantLines = this.mainComponent.selectAll(".participantBars")
                                    .data(this.streams[PARTICIPANTS])
                                    .enter().append("rect")
                                    .attr("class", "participantBars")
                                    .attr("fill",  "var( --border-color)")
                                    .attr("x", (d,i) => 0 + this.width*0.025)
                                    .attr("width", (d) => this.width*0.95) 
                                    .attr("y", (d) => this.yScale(d[cc.END_Y_VALUE]) + this.barHeight/2)
                                    .attr("height", this.barHeight / 6)              

        
        // Adds the devices
        this.devices = this.mainComponent.selectAll(".deviceBars")
                .data(this.streams[DEVICES])
                .enter().append("rect")
                .attr("class", "deviceBars")
                .attr("x", 0)
                .attr("width", this.width) 
                .attr("y", (d) => this.yScale(d[cc.Y_VALUE]))
                .attr("height", this.barHeight)  
                .attr("stroke", "var(--border-color)") // Set stroke color
                .attr("stroke-width", 0) // Thicken the border
                .attr("fill",  "rgba(0,0,0,0)")
                .on("mouseover", (event, d) => {

                    d3.select(event.currentTarget)
                    .transition()
                    .duration(200)
                    .attr("stroke", "var(--border-color)") // Set stroke color
                    .attr("stroke-width", 1) // Thicken the border                    

                    tooltip.html(this.buildHtml(d))
                    .style("left", event.pageX + "px")
                    .style("top", event.pageY + "px")



                    tooltip.transition()
                        .duration(200)
                        .style("opacity", .9);

                    
                })
                .on("mouseout", (event, d) => {

                    d3.select(event.currentTarget)
                        .transition()
                        .duration(200)                        
                        .attr("stroke-width", 0);

                    tooltip
                        .style("opacity", 0)
                        .style("left", "-1000px")
                        .style("top", "-1000px");

                        
                });

               

        // Adds x axis
        this.mainComponent.append("g")
                            .call(d3.axisTop(this.xScale))
                            .style("font-size", this.parameters[cc.X_LABEL_FONT_SIZE] + "px")

        // Adds x axis
        this.mainComponent.append("g")
                            .call(d3.axisBottom(this.xScale))
                            .attr("transform", "translate(0," + (this.height +  2*this.barHeight) + ")")
                            .style("font-size", this.parameters[cc.X_LABEL_FONT_SIZE] + "px")


                       

        const tooltip = d3.select('body')
                            .append('div')
                            .attr('id', 'tooltip')
                            .attr('style', 'position: absolute; opacity: 1;')
                            .style("position","absolute")            
                            .style("left", "300px" )
                            .style("top", "300px" )
                            .html( this.buildHtml({}))                                   
                        




    }


    // Function that updates the chart
    updateData(newData){

        super.updateData(newData)

        // Updates variables
        this.barValues = newData.map((d) => d[cc.VALUES]).flat()
        this.labelValues = newData.map((d) => d[cc.LABELS]).flat()

        this.y = d3.scaleLinear().range([this.height, 0])
                .domain([Math.min(-1,...this.barValues), Math.max(0,...this.barValues)]);

        this.mainComponent.select("#yAxis")
                .transition()
                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                .call(d3.axisLeft(this.y));


        // Removes Hover an refreshes label    
        this.bars.each((_,i, nodes) => {

            d3.select(nodes[i]).on("mouseover", null)
                               .on("mouseout", null)
                               .select("title")
                                .text(`${this.labelValues[i]}`);;
        })

        this.mainComponent.selectAll(".bar")
                .data(this.barValues)
                .transition()
                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                .attr("height", (val) => Math.abs(this.y(0) - this.y(val)))
                .attr("y", (val) => this.y(Math.max(0,val)))
                .on("end", () => {

                    // Adds Hover After animation is complete   
                    this.bars.each((_,i, nodes) => {

                        d3.select(nodes[i]).on("mouseover", (_) => {
                            d3.select(nodes[i]).transition()
                                                .duration('400')
                                                .style("opacity", 1);

                        }).on("mouseout", (_) => {
                            d3.select(nodes[i]).transition()
                                                .duration('400')
                                                .style("opacity", this.getOpacity( this.getNumGroup(i, this.numBars), this.opacityJump));

                        })                
                    })

                });    
                       
    }




    // Support Functions
    // --------------------------

    // Support functions
    getNumGroup = (i, numBars) =>
    {
        return( Math.floor(i / numBars))
    }


    getNumInsideGroup = (i, numBars) =>
    {
        return( i % numBars)
    }

    // Opacity jump
    getOpacity = (numInside, opacityJump) => {
        
        return(1 - ((numInside)*(opacityJump)))
    }

    getColorScheme(numElements)
    {
        // Builds default color scheme
        return(d3.scaleOrdinal().domain([0,numElements])
                                        .range(["var(--color-scenario-1)","var(--color-scenario-2)"]))
    }

    buildHtml(device) 
    {

        return(
            `<div class="streamQualityTooltip">
                <h3>${device[STREAM_TYPE]}</h3>
                <h4>${device[DEVICE_ID]}</h4>
                <p>Name: ${device[NAME]}</p> 
                <p>Last Name: ${device[LAST_NAME]}</p> 
                <p>SEWA Id: ${device[SEWA_ID]}</p>


            </div>`
        )

    }

 }

 // Support functions


 const extractStreams = (data) =>
 {

     let participants = [] 
     let heart_rate = []
     let sleep = []
     let geolocation = []
     let temperature = []
     let devices = []
     

     let currentY = 0
     Object.keys(data).forEach(k => {
         let startY = currentY
         let par = data[k]
         
         // Geolocation
         if(con.SUMMARY_GEOLOCATION in par)
         {
             Object.keys(par[con.SUMMARY_GEOLOCATION]).forEach(pk => {
                 geolocation = geolocation.concat(par[con.SUMMARY_GEOLOCATION][pk].map(info => {return({...info, [cc.Y_VALUE] : currentY})}));                 
                 devices.push({
                    [NAME] : par[NAME],
                    [LAST_NAME] : par[LAST_NAME],
                    [SEWA_ID] : par[SEWA_ID],
                    [STREAM_TYPE] : "Geolocation",
                    [DEVICE_ID] : pk,
                    [cc.Y_VALUE] : currentY
                 })
                 currentY += 1
             });

             
         }
         else
             currentY += 1

         // Heart Rate
         if(con.SUMMARY_HEART_RATE in par)
         {
             Object.keys(par[con.SUMMARY_HEART_RATE]).forEach(wk => {
                 heart_rate = heart_rate.concat(par[con.SUMMARY_HEART_RATE][wk].map(info => {return({...info, [cc.Y_VALUE] : currentY})}));
                 devices.push({
                    [NAME] : par[NAME],
                    [LAST_NAME] : par[LAST_NAME],
                    [SEWA_ID] : par[SEWA_ID],
                    [STREAM_TYPE] : "Heart Rate",
                    [DEVICE_ID] : wk,
                    [cc.Y_VALUE] : currentY
                 })
                 currentY += 1
             });
         }
         else
             currentY += 1    

         // Sleep
         if(con.SUMMARY_SLEEP in par)
         {
             Object.keys(par[con.SUMMARY_SLEEP]).forEach(sk => {
                 sleep = sleep.concat(par[con.SUMMARY_SLEEP][sk].map(info => {return({...info, [cc.Y_VALUE] : currentY})}));
                 devices.push({
                    [NAME] : par[NAME],
                    [LAST_NAME] : par[LAST_NAME],
                    [SEWA_ID] : par[SEWA_ID],
                    [STREAM_TYPE] : "Sleep",
                    [DEVICE_ID] : sk,
                    [cc.Y_VALUE] : currentY
                 })
                 currentY += 1
             });
         }
         else
             currentY += 1    
        
             
         // Temperature
         if(con.SUMMARY_TEMPERATURE in par)
         {
             Object.keys(par[con.SUMMARY_TEMPERATURE]).forEach(sk => {
                 temperature = temperature.concat(par[con.SUMMARY_TEMPERATURE][sk].map(info => {return({...info, [cc.Y_VALUE] : currentY})}));
                 devices.push({
                    [NAME] : par[NAME],
                    [LAST_NAME] : par[LAST_NAME],
                    [SEWA_ID] : par[SEWA_ID],
                    [STREAM_TYPE] : "Temperature",
                    [DEVICE_ID] : sk,
                    [cc.Y_VALUE] : currentY
                 })
                 currentY += 1
             });
         }
         else
             currentY += 1   
             
             
         participants.push({
            [cc.LABEL] : k, 
            [cc.START_Y_VALUE] : startY,
            [cc.END_Y_VALUE] : currentY})

         currentY += 1


     })

     return([ currentY, {
         [PARTICIPANTS] :  participants,
         [HEART_RATE] : heart_rate,
         [GEOLOCATION] : geolocation,
         [TEMPERATURE] : temperature,
         [SLEEP] : sleep,
         [DEVICES] : devices
     }])

 }
 

  