// Sensor Functions
import * as con from "./../Constants"

export const getExpiryDaysByBrand = (brand) =>
{
    switch (brand) {
        case con.GOVEE:
        case con.KESTREL:
            return 20
        case con.HOBO:
            return 180    
        default:
            return 20;
    }

}