import React, { useEffect, useState } from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import * as con from "../Constants"
import { Button, Card, Input, Space, Spin } from "antd";
import { Typography } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, } from '@ant-design/icons';
import { formatDistance } from 'date-fns';

const { Title, Text } = Typography;

function DashboardDeploymentStatus() {


  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const [participantArray, setParticipantArray] = useState([])


  const [inputValue, setInputValue] = useState(() => null)
  const [filterText, setFilterText] = useState(() => null)

  useEffect(() => {

    if (participants !== null) {
      let parArray = Object.values(participants)
      if (filterText !== null && filterText !== undefined && filterText !== "")
        parArray = parArray.filter(ob => ob[con.AT_PUBLIC_ID].toUpperCase().includes(filterText.toUpperCase()))

      parArray.sort((a, b) => new Date(b[con.AT_JOINED_DATE]) - new Date(a[con.AT_JOINED_DATE]));
      if (parArray.length > 0)
        parArray = parArray.slice(0, Math.min(10, parArray.length))

      setParticipantArray(parArray)
    }

  }, [participants, filterText])


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };


  const DeploymentStatusCard = ({ participant }) => {

    const isCorrect = participant[con.AT_HAS_ACTIVE_SENSORS] && participant[con.AT_HAS_ACTIVE_WEARABLES] && participant[con.AT_HAS_ACTIVE_PHONES]

    return (
      <Card style={{ marginTop: "2%", border: isCorrect ? "4px solid #63b06d" :  "4px solid #b7362d"}}
        headStyle={{ backgroundColor: isCorrect ? "var(--success-color-1)" :  "var(--warning-color-1)" }}
        title={participant[con.AT_PUBLIC_ID]}>
        <div style={{ marginBottom: "15px", display: "flex", justifyContent: "space-around" }}>
          <Text strong>Sensors: {participant[con.AT_HAS_ACTIVE_SENSORS] ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#e91822" />} {`(${participant[con.AT_TOTAL_ACTIVE_SENSORS]})`}</Text>
          <Text strong>Wearable: {participant[con.AT_HAS_ACTIVE_WEARABLES] ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#e91822" />}</Text>
          <Text strong>Phone: {participant[con.AT_HAS_ACTIVE_PHONES] ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#e91822" />}</Text>
        </div>
        <Card  headStyle={{ backgroundColor: isCorrect ? "var(--success-color-1-transparent)" :  "var(--warning-color-1-transparent)" }} type="inner" size="small" style={{ paddingtop: "0px" }} title="Membership Number">
          {participant[con.AT_SEWA_ID]}
        </Card>
        <Card  headStyle={{ backgroundColor: isCorrect ? "var(--success-color-1-transparent)" :  "var(--warning-color-1-transparent)" }} type="inner" size="small" title="Name">
          {participant[con.AT_NAME]}
        </Card>
        <Card  headStyle={{ backgroundColor: isCorrect ? "var(--success-color-1-transparent)" :  "var(--warning-color-1-transparent)" }} type="inner" size="small" title="Last Name">
          {participant[con.AT_LAST_NAME]}
        </Card>
        <Card  headStyle={{ backgroundColor: isCorrect ? "var(--success-color-1-transparent)" :  "var(--warning-color-1-transparent)" }} type="inner" size="small" title="Record Added">
          {formatDistance(new Date(participant[con.AT_JOINED_DATE]), new Date(), { addSuffix: true })}
        </Card>

      </Card>
    )
  }

  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant's Deployment Status`}</Title>
      <Space direction="vertical" >
        <Space direction="horizontal" >
          <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh</Button>
          <Input placeholder="Filter" value={inputValue} onChange={handleInputChange} />
          <Button type="primary" onClick={() => setFilterText(inputValue)}>Filter</Button>
          <Button type="primary" onClick={() => {setInputValue(null);setFilterText(null)}} danger>Clear</Button>
        </Space>

      </Space>

      <div style={{ marginTop: "5%" }}>
        {
          status === con.NOT_STARTED || status === con.LOADING || participants === null
            ? <Spin />
            : status === con.ERROR
              ? <div>Error</div>
              : <div>
                {
                  participantArray.length > 0
                    ? participantArray.map(par => <DeploymentStatusCard key={par[con.ID]} participant={par} />)
                    : <Title level={3}>{`No records found`}</Title>
                }
              </div>
        }
      </div>
    </div>
  )
}

export default DashboardDeploymentStatus