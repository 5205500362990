import './App.css';

import { RefCurrentPage } from './hooks/NavigationHooks';
import * as con from './Constants';
import DashboardSelect from './pages/DashboardSelect';
import DashboardExpiryParticipants from './pages/DashboardExpiryParticipants';
import { useEffect, useState } from 'react';
import { StateStoreValue } from './hooks/GeneralStoreHooks';

import { Button, Typography, Form, Input, Space } from 'antd';
import DashboardDeploymentProgress from './pages/DashboardDeploymentProgress';
import DashboardDeploymentStatus from './pages/DashboardDeploymentStatus';
import DashboardDataStreamQuality from './pages/DashboardDataStreamQuality';
import DashboardCollectionStatus from './pages/DashboardCollectionStatus';
import DashboardSingleCollectionStatus from './pages/DashboardSingleCollectionStatus';
import { fetchParticipantData, fetchPendingIssues, fetchPhoneData, fetchPhonePlacementData, fetchReceivedSensorFiles, fetchSensorData, fetchSensorHousePlacementData, fetchWearableData, fetchWearablePlacementData } from './store/actions/datasetActions'


const { Text } = Typography;

function App() {

  // Navigation
  const currentPage = RefCurrentPage();

  // Password
  const [isLoggedIn, setIsLoggedIn] = StateStoreValue(con.LOGGED_IN)
  const [showMessage, setShowMessage] = useState(false)


  useEffect(() => {
    fetchParticipantData()
    fetchSensorData()
    fetchSensorHousePlacementData()
    fetchPhoneData()
    fetchPhonePlacementData()
    fetchWearableData()
    fetchWearablePlacementData()
    fetchReceivedSensorFiles()
    fetchPendingIssues()
  }, [])


  const logIn = (values) => {

    let password = values["password"]

    if (password !== null && password !== undefined && password.toUpperCase() === con.PASSWORD.toUpperCase()) {
      setIsLoggedIn(true)
      setShowMessage(false)
    }
    else
      setShowMessage(true)

  }

  // Uses a function through switch to render
  const renderDesktopComponents = (page) => {

    switch (page) {
      case con.PAGE_START:
        return (<DashboardSelect />)
      case con.PAGE_DASHBOARD_EXPIRY_PARTICIPANTS:        
        return (<DashboardExpiryParticipants />)
      case con.PAGE_DASHBOARD_DEPLOYMENT_STATUS:
        return (<DashboardDeploymentStatus />)
      case con.PAGE_DASHBOARD_COLLECTION_STATUS:
          return (<DashboardCollectionStatus />)                
      case con.PAGE_DASHBOARD_DEPLOYMENT_PROGRESS:
        return (<DashboardDeploymentProgress />)
      case con.PAGE_DASHBOARD_DATA_STREAM_QUALITY:
          return (<DashboardDataStreamQuality />)
      case con.PAGE_DASHBOARD_SINGLE_COLLECTION_STATUS:
          return (<DashboardSingleCollectionStatus />)
      default:
        return (<DashboardSelect />);
    }
  }



  return (
    <div>
        {
          con.DEBUG 
          ? <p style={{margin :" 0px"}}>DEBUG IS ON</p>
          : <div></div>
        }
      <div className="App">
         {
          con.DEBUG || isLoggedIn
            ? renderDesktopComponents(currentPage)
            : <Form
              name="basic"
              onFinish={logIn}
              onFinishFailed={logIn}
              autoComplete="off"
            >
              <Space direction="vertical">
                <Form.Item name="password">
                  <Input.Password placeholder="input password" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" >Log In</Button>
                </Form.Item>
                {showMessage
                  ? <Text type="danger">Password is not correct</Text>
                  : <div></div>
                }
              </Space>
            </Form>
        }
      </div>
    </div>
  );
}

export default App;
