// Reducer for all the store
import * as con from "../../Constants"


// Initial State
export const initialState = {
    // Status
    [con.STATUS_DATASET_PARTICIPANTS]: con.NOT_STARTED,
    [con.STATUS_DATASET_SENSORS]: con.NOT_STARTED,
    [con.STATUS_DATASET_HOUSES]: con.NOT_STARTED,
    [con.STATUS_DATASET_PARTICIPANT_PLACEMENT]: con.NOT_STARTED,
    [con.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT]: con.NOT_STARTED,
    [con.STATUS_DATASET_RECEIVED_SENSOR_FILES]: con.NOT_STARTED,
    [con.STATUS_DATASET_ISSUES]: con.NOT_STARTED,



    // Dataset
    [con.DATASET_PARTICIPANTS]: null,
    [con.DATASET_SENSORS]: null,
    [con.DATASET_HOUSES]: null,
    [con.DATASET_PARTICIPANT_PLACEMENT]: null,
    [con.DATASET_SENSOR_HOUSE_PLACEMENT]: null,
    [con.DATASET_RECEIVED_SENSOR_FILES]: null,
    [con.DATASET_ISSUES] : null,


    // Variables
    [con.LOGGED_IN]: false,
    [con.DOWNLOADING_PARTICIPANTS] : false,
    [con.SELECTED_PARTICIPANT] : null

}



/**
 * Main actions function.
 * The structure of the function is a main switch with a final clean-up section at the end
 * @param {object} state - Current state of the store   
 * @param {object} action  - Object containing the action and the payload 
 * @returns The new state
 */
export default function setStateFun(state = initialState, action) {


    switch (action.type) {
        case con.SET_VALUE:
            state = { ...state, [action.payload[con.ID]]: action.payload[con.VALUE] }
            break

        case con.UPDATE_OBJECT_VALUE:
            state = { ...state, [action.payload[con.ID]]: {...state[action.payload[con.ID]], ...action.payload[con.VALUE]}}
            break

        case con.RESET_ALL_DATASETS:
            state = {...state,
                    // Status
                    [con.STATUS_DATASET_PARTICIPANTS]: con.NOT_STARTED,
                    [con.STATUS_DATASET_SENSORS]: con.NOT_STARTED,
                    [con.STATUS_DATASET_HOUSES]: con.NOT_STARTED,
                    [con.STATUS_DATASET_PARTICIPANT_PLACEMENT]: con.NOT_STARTED,
                    [con.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT]: con.NOT_STARTED,
                    [con.STATUS_DATASET_RECEIVED_SENSOR_FILES]: con.NOT_STARTED,


                    // Dataset
                    [con.DATASET_PARTICIPANTS]: null,
                    [con.DATASET_SENSORS]: null,
                    [con.DATASET_HOUSES]: null,
                    [con.DATASET_PARTICIPANT_PLACEMENT]: null,
                    [con.DATASET_SENSOR_HOUSE_PLACEMENT]: null,
                    [con.DATASET_RECEIVED_SENSOR_FILES]: null}
            break

        default:
            break

    }

    // Final manipulations
    // Cleanup scripts

    return ({ ...state })


}

