import React, { useEffect, useState } from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import { updateSinglePhone, updateAccountWithLastSynched, updateSingleSensorFiles, logBatteryChange, markIssueAsDone } from '../store/actions/datasetActions'
import * as con from "../Constants"
import { Button, Card, DatePicker, Input, Space, Spin, Table, Tag, TimePicker } from "antd";
import { Typography } from 'antd';
import {  formatDate, parseISO } from 'date-fns';
import { formatDistanceShort, formatToIndiaTime, getDaysBetweenDates } from '../utils/dateFunctions';
import { getLastSync } from '../utils/fitbitFunctions';
import { getColorOfExpiry, getColorOfLastUpload, getTextOfExpiry } from '../utils/generalFunctions';
import { SymbolForStatus } from '../utils/devicesStatusFunctions';
import Modal from 'antd/es/modal/Modal';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';

const { Title, Text } = Typography;


export const DeviceStatusSection = ({status, message}) =>
{

  return(
    <div style={{ paddingBottom: "18px", display: "flex", flexDirection: "column"}}>
      <Text style={{ marginBottom: "2px"}} strong>Collection Status: <SymbolForStatus status={status}/></Text>
      <Text>{message}</Text>
    </div>
  )

}

export const CustomTag = ({tagText, i}) => 
{
  return(<Tag color={con.TAG_COLORS[i % con.TAG_COLORS.length]} 
              style={{ marginLeft : "3px",
                      marginRight : "3px"}}>
                  {tagText}
         </Tag>)
}

export const IssueSection = ({issue}) =>
{

  const [isModalOpen, setIsModalOpen] = useState(() => false)
  const [confirmLoading, setConfirmLoading] = useState(() => false)
  const [noteText, setNoteText] = useState(()=> null)

  const localMarkIssueAsDone = async ()=>
  {
    setConfirmLoading(true)

    await markIssueAsDone(issue, noteText)

    setIsModalOpen(false)
    setConfirmLoading(false)


  }


  return(<Card key={issue[con.ID]} headStyle={{ backgroundColor: "var(--primary-color-6-transparent)" }} type="inner" size="small" style={{ paddingtop: "0px" }} title={issue[con.AT_PUBLIC_ID]}>
             <div className='verticalSection' style={{ paddingBottom : "15px" }}>
              <p style={{ margin: "1px"}}><strong>Tags</strong></p>
              <div className='horizontalSection'>
                {issue[con.AT_TAGS].map((t,i) => <CustomTag key={i} tagText={t} i={i}/>)}
              </div>

              <Text style={{ marginTop: "20px"}}><strong>Description</strong></Text>
              <Text style={{ marginTop: "1px"}}>{issue[con.AT_DESCRIPTION]}</Text>

              <Text style={{ marginTop: "20px"}}><strong>Action</strong></Text>
              <Text style={{ marginTop: "1px"}}>{issue[con.AT_ACTION]}</Text>

              <div style={{marginTop : "30px"}}>
                {
                  issue[con.AT_STATUS] === con.AT_DONE
                  ? <Text><strong>Issue Marked as Done </strong><SymbolForStatus status={con.OK} /></Text>
                  : <Button type="primary" onClick={() => setIsModalOpen(true)}>Mark as Done</Button>
                }
                
                <Modal title={"Notes"}
                       open={isModalOpen} 
                       onCancel={()=> setIsModalOpen(false)}
                       onOk={() => localMarkIssueAsDone()}
                       okText={"Mark as Done"}
                       confirmLoading={confirmLoading}
                       >
                      <div className='verticalSection' >
                        <TextArea rows={3} value={noteText} onChange={e => setNoteText(e.target.value)}/>
                      </div>

                </Modal>
              </div>

             </div>
        </Card>)
}



export const SensorSection = ({sensor}) =>
{

    // Updating Sensor
    const [updatingSensor, setUpdatingSensor] = useState(() => false)

    // Battery
    const [batteryDialogOpen, setBatteryDialogOpen] = useState(() => false)
    const [dateBatteryChange, setDateBatteryChange] = useState(() =>  formatDate(new Date(), "yyyy-MM-d" ))
    const [timeBatteryChange, setTimeBatteryChange] = useState(() =>  formatDate(new Date(), "HH:mm" ))
    const [isLoggingBatteryChange, setIsLoggingBatteryChange] = useState(false)



    const localLogBatteryChange = async (serial) =>
    {
      setBatteryDialogOpen(false)
      setIsLoggingBatteryChange(true)
      await logBatteryChange(serial, dateBatteryChange, timeBatteryChange)
      setIsLoggingBatteryChange(false)

    }


    const refreshSensorFiles = async (sensorSerial) =>
    {
      setUpdatingSensor(true)
      await updateSingleSensorFiles(sensorSerial)
      setUpdatingSensor(false)
    }


    const getDaysStyle = (val, record) =>
    {
      let response = {
        color : "black",
        fontWeight : "normal"
      }
      if(val <= 10)
      {
        response = {
          ...response,
          color : "var(--warning-color-1)",
          fontWeight : "bold"

        }
      }

      
      return response
    }

    const getEndDateStyle = (val, record) =>
    {
      let response = {
        color : "black",
        fontWeight : "normal"
      }
      
      if(getDaysBetweenDates(parseISO(record[con.AT_END_DATE]), parseISO(record[con.AT_DATE_UPLOADED])) >= 2)
      {
        response = {
          ...response,
          color : "var(--warning-color-1)",
          fontWeight : "bold"

        }
      }

      
      return response
    }


    const sensorFilesColumns = [
      {
        title: 'Uploaded',
        dataIndex: con.AT_DATE_UPLOADED,
        key: con.AT_DATE_UPLOADED,
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => parseISO(a[con.AT_DATE_UPLOADED]) - parseISO(b[con.AT_DATE_UPLOADED]),
        render: (val, record) => <p style={{textAlign: 'center'}}>{formatDistanceShort(val)}</p>
      },
      {
        title: 'Start Date',
        dataIndex: con.AT_START_DATE,
        key: con.AT_START_DATE,
        align: 'center',
        render: (val, record) => <p style={{textAlign: 'center'}}>{formatToIndiaTime(val)}</p>
      },
      {
        title: 'End Date',
        dataIndex: con.AT_END_DATE,
        key: con.AT_END_DATE,
        align: 'center',
        render: (val, record) => <p style={{textAlign: 'center', ...getEndDateStyle(val, record)}}>{formatToIndiaTime(val)}</p>
      },
      {
        title: 'Days',
        dataIndex: con.AT_SIZE_IN_DAYS,
        key: con.AT_SIZE_IN_DAYS,
        align: 'center',
        render: (val, record) => <p style={{textAlign: 'center', ...getDaysStyle(val, record)}}>{val}</p>
      },
    ];



    return(<Card key={sensor[con.ID]} headStyle={{ backgroundColor: "var(--primary-color-5-transparent)" }} type="inner" size="small" style={{ paddingtop: "0px" }} title={sensor[con.AT_SERIAL]}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingBottom : "15px" }}>
              {
                  updatingSensor === true
                  ? <div>
                      <Spin/>
                    </div>
                  : <Button type="primary" onClick={() => refreshSensorFiles(sensor[con.AT_SERIAL])}>Refresh</Button>

                }                       
              </div>
              
              <DeviceStatusSection status={sensor[con.AT_DEVICE_COLLECTION_STATUS]} message={sensor[con.AT_DEVICE_COLLECTION_MESSAGE]} />
              <div style={{ marginBottom: "15px", display: "flex", flexDirection: "row", justifyContent: "center" }}>               
                <Text style={{marginRight : "10px"}}><Text strong>Brand: </Text>{sensor[con.AT_BRAND]}</Text>
                <Text style={{marginLeft : "10px"}}><Text strong>Model: </Text>{sensor[con.AT_MODEL]}</Text>
              </div>
              <div>
                <p style={{ margin: "1px"}}><strong>Placement Start: </strong>{formatToIndiaTime(sensor[con.AT_START_DATE], false)} ({formatDistanceShort(sensor[con.AT_START_DATE])})</p>
                <p style={{ margin: "1px"}}><strong>Latest Battery Change: </strong>{formatToIndiaTime(sensor[con.AT_LATEST_BATTERY_CHANGE], false)} ({formatDistanceShort(sensor[con.AT_LATEST_BATTERY_CHANGE])})</p>
                <div style={{marginTop : "10px"}}>
                  {
                    isLoggingBatteryChange === true
                      ? <div>
                          <Spin />
                        </div>
                      : <Button  type="primary" onClick={() => setBatteryDialogOpen(true)}>Log Battery Change</Button>

                  }
                </div>
                {/* Creating multiple mpodals and only calling the last one */}
                <Modal title="Battery Change" open={batteryDialogOpen} onOk={() => localLogBatteryChange(sensor[con.AT_SERIAL])} onCancel={() => setBatteryDialogOpen(false)} okText="Log Battery Change" cancelText="Cancel">
                  <div className='verticalSection' >
                    <Text>Date</Text>
                    <DatePicker style={{marginBottom : "10px"}} onChange={(d, dateString ) => setDateBatteryChange(formatDate(new Date(dateString), "yyyy-MM-d"))} value={dayjs(dateBatteryChange)}/>
                    <Text>Time</Text>
                    <TimePicker style={{marginBottom : "10px"}} onChange={(time) => {setTimeBatteryChange(time.format("HH:mm"))}} value={dayjs(timeBatteryChange, "HH:mm")} format={"HH:mm"} />
                  </div>
                </Modal>
              </div>
              <div style={{ paddingTop : "15px", marginBottom: "15px", display: "flex", flexDirection: "column" }}>
                <Text strong>Uploaded Files</Text>
                {
                  sensor[con.AT_RECEIVED_SENSOR_FILES].length === 0
                  ? <Text>No files received in the last month</Text>
                  : <Table dataSource={sensor[con.AT_RECEIVED_SENSOR_FILES]} columns={sensorFilesColumns} size="small" pagination={{ pageSize: 5 }} />

                }
              </div>             
              
            </Card>
    )
}

export const DataCollectionStatusCard = ({ participant }) => {

    const isCorrect = participant[con.AT_HAS_ACTIVE_SENSORS] && participant[con.AT_HAS_ACTIVE_WEARABLES] && participant[con.AT_HAS_ACTIVE_PHONES]
    
    let activeSensors = participant[con.AT_ACTIVE_SENSORS]
    let phone = participant[con.AT_ACTIVE_PHONE]
    let wearablePlacement = participant[con.AT_ACTIVE_WEARABLE]
    let groundTeamIssues = participant[con.AT_GROUND_TEAM_ISSUES] === undefined || participant[con.AT_GROUND_TEAM_ISSUES] === null ? [] : participant[con.AT_GROUND_TEAM_ISSUES]

    const [updatingPhone, setUpdatingPhone] = useState(() => false)
    const [updatingWearable, setUpdatingWearable] = useState(() => false)




    const refreshPhone = async (phoneSerial) =>
    {
      setUpdatingPhone(true)
      await updateSinglePhone(phoneSerial)
      setUpdatingPhone(false)
    }


    const refreshWearable = async (email, requestId, token) =>
    {
      setUpdatingWearable(true)
      let [response, lastSynched] = await getLastSync(requestId, token)

      if(response === con.OK)
      {
        // Updates Airtable
        await updateAccountWithLastSynched(email, lastSynched)

      }
      setUpdatingWearable(false)
    }      




  return (
    <div className='pageContainer'>
      <Card style={{ marginTop: "2%", border: isCorrect ? "4px solid var(--success-color-1)" :  "4px solid var(--warning-color-1)"}}
        headStyle={{ backgroundColor: isCorrect ? "var(--success-color-1-transparent)" :  "var(--warning-color-1-transparent)" }}
        title={participant[con.AT_PUBLIC_ID]}>

        <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <p style={{ margin: "1px"}}><strong>Date Joined: </strong>{formatToIndiaTime(participant[con.AT_JOINED_DATE], false)} ({formatDistanceShort(participant[con.AT_JOINED_DATE])})</p>
          <p style={{ margin: "1px", color : getColorOfExpiry(participant[con.AT_DAYS_UNTIL_EXPIRE])}}><strong style={{color : "black"}}>Next Collection: </strong>{getTextOfExpiry(participant[con.AT_DAYS_UNTIL_EXPIRE])}</p>
          <p style={{ margin: "1px", color : getColorOfLastUpload(participant[con.AT_DAYS_SINCE_LAST_UPLOAD])}}><strong style={{color : "black"}}>Last Collection: </strong>{`${formatToIndiaTime(participant[con.AT_LATEST_COLLECTION_DATE])} (${formatDistanceShort(participant[con.AT_LATEST_COLLECTION_DATE])})`}</p>
        </div>

        {/* Issues */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-6)" }} className='no-bottom-margin' type="inner" size="small" style={{ paddingtop: "0px" }} title="Issues">          
          <div style={{ marginBottom: "3px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
            {
              groundTeamIssues.length === 0 
              ? <Text style={{marginBottom : "25px"}}><strong>No Pending Issues </strong><SymbolForStatus status={con.OK} /></Text>
              : groundTeamIssues.map(i => <IssueSection issue={i} />)             
            }
          </div>

        </Card>

        {/* Intake Survey */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-7)" }} className='no-bottom-margin' type="inner" size="small" style={{ paddingtop: "0px" }} title="Intake Survey">          
          <div style={{ marginBottom: "3px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
              <Text style={{marginBottom : "25px"}}><strong>{participant[con.AT_INTAKE_SURVEY][con.AT_DEVICE_COLLECTION_MESSAGE]} </strong><SymbolForStatus status={participant[con.AT_INTAKE_SURVEY][con.AT_DEVICE_COLLECTION_STATUS]} /></Text>
          </div>

        </Card>


        {/* Sensors */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-5)" }} className='no-bottom-margin' type="inner" size="small" style={{ paddingtop: "0px" }} title="Bundle Sensors">          
          <div style={{ marginBottom: "3px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
            {
              activeSensors.map(s => <SensorSection sensor={s} />)             
            }
          </div>

        </Card>

        {/* Phone */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-1)" }} className='no-bottom-margin' type="inner" size="small" title="Location Tracker App">
          {
              phone === null
              ? <Text>Participant has no active phone placement</Text>
              : <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                  <DeviceStatusSection status={phone[con.AT_DEVICE_COLLECTION_STATUS]} message={phone[con.AT_DEVICE_COLLECTION_MESSAGE]} />
                  <Text style={{ marginBottom: "15px"}}><Text strong>Phone Serial: </Text>{phone[con.AT_SERIAL]}</Text>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <Text style={{marginRight : "10px"}}><Text strong>Last Uploaded: </Text>{formatDistanceShort(phone[con.AT_LAST_UPLOADED])}</Text>
                    <Text style={{marginLeft : "10px"}}><Text strong>Latest Record: </Text>{formatDistanceShort(phone[con.AT_LATEST_RECORD])}</Text>
                  </div>
                  <div style={{ marginTop : "5px", display: "flex", flexDirection: "row", justifyContent: "right" }}>
                    {
                      updatingPhone === true
                      ? <div>
                          <Spin />
                        </div>
                      : <Button type="primary" onClick={() => refreshPhone(phone[con.AT_SERIAL])}>Refresh</Button> 

                    }                    
                  </div>
                </div>
          }         
        </Card>

        {/* Wearable */}
        <Card headStyle={{ backgroundColor: "var(--primary-color-3)" }} className='no-bottom-margin' type="inner" size="small" title="Fitbit Device">
        {
              wearablePlacement === null
              ? <Text>Participant has no active Fitbit placement</Text>
              : <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                  <DeviceStatusSection status={wearablePlacement[con.AT_DEVICE_COLLECTION_STATUS]} message={wearablePlacement[con.AT_DEVICE_COLLECTION_MESSAGE]} />
                  <Text style={{ marginBottom: "15px"}}><Text strong>Fitbit Serial: </Text>{wearablePlacement[con.AT_WEARABLE][con.AT_SERIAL]}</Text>
                  <Text style={{ marginBottom: "15px"}}><Text strong>Email: </Text>{wearablePlacement[con.AT_EMAIL]}</Text>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <Text style={{marginRight : "10px"}}><Text strong>Last Uploaded: </Text>{formatDistanceShort(wearablePlacement[con.AT_LAST_UPLOADED])}</Text>                   
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                  {
                      updatingWearable === true
                      ? <div>
                          <Spin />
                        </div>
                      : <Button type="primary" onClick={() => refreshWearable(wearablePlacement[con.AT_EMAIL], wearablePlacement[con.AT_FITBIT_REQUEST_ID], wearablePlacement[con.AT_FITBIT_TOKEN])}>Refresh</Button> 

                    }   
                  </div>
                </div>
          }    
        </Card>
      </Card>
    </div>
  )
}

function DashboardCollectionStatus() {


  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const [participantArray, setParticipantArray] = useState([])



  const [inputValue, setInputValue] = useState(() => null)
  const [filterText, setFilterText] = useState(() => null)

  useEffect(() => {

    if (participants !== null) {
      let parArray = Object.values(participants)
      if (filterText !== null && filterText !== undefined && filterText !== "")
        parArray = parArray.filter(ob => ob[con.AT_PUBLIC_ID].toUpperCase().includes(filterText.toUpperCase()))

      parArray.sort((a, b) => new Date(b[con.AT_JOINED_DATE]) - new Date(a[con.AT_JOINED_DATE]));
      if (parArray.length > 0)
        parArray = parArray.slice(0, Math.min(10, parArray.length))

      setParticipantArray(parArray)
    }

  }, [participants, filterText])


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };



  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant's Data Collection Status`}</Title>
      <Space direction="vertical" >
        <Space direction="horizontal" >
            <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh All</Button>
            <Input placeholder="Filter" value={inputValue} onChange={handleInputChange} />
            <Button type="primary" onClick={() => setFilterText(inputValue)}>Filter</Button>
            <Button type="primary" onClick={() => {setInputValue(null);setFilterText(null)}} danger>Clear</Button>
          </Space>

      </Space>

      <div style={{ marginTop: "5%" }}>
        {
          status === con.NOT_STARTED || status === con.LOADING || participants === null
            ? <Spin />
            : status === con.ERROR
              ? <div>Error</div>
              : <div>
                {
                  participantArray.length > 0
                    ? participantArray.map(par => <DataCollectionStatusCard key={par[con.ID]} participant={par} />)
                    : <Title level={3}>{`No records found`}</Title>
                }
              </div>
        }
      </div>
    </div>
  )
}

export default DashboardCollectionStatus