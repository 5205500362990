// Airtable functions
import Airtable from "airtable";
import { format, formatISO, subMonths } from "date-fns";
import * as con from './../Constants';
import { nowInIndia } from "./dateFunctions";
import { exportToExcel } from "./ExcelFunctions";
import { arrayToDict, filterObjectByKeys, isNull } from "./generalFunctions";


// Airtable
const at_base = new Airtable({ apiKey: con.CONFIG[con.AT_API_KEY] }).base(con.CONFIG[con.AT_APP_ID]);



// General Methods
export const getDataFromTable = async (tableId) => {

    let records = await at_base(tableId).select().all();
    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })

    records = arrayToDict(records, con.ID)

    return (records)

}


// Specific Methods
export const getAllParticipants = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_PARTICIPANTS])
    return (records)
}

export const getAllSensors = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_SENSORS])
    return (records)
}

export const getAllHouses = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_HOUSES])
    return (records)
}

export const getAllSensorHousePlacement = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_SENSOR_HOUSE_PLACEMENT])
    return (records)
}

export const getAllParticipantPlacement = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_PARTICIPANTS_PLACEMENT])
    return (records)
}

export const getAllPhones = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_PHONES])
    return (records)
}


export const getAllPhonesPlacement = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_PHONE_PLACEMENTS])
    return (records)
}

export const getAllWearables = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_WEARABLES])
    return (records)
}

export const getAllWearablePlacements = async () => {
    const records = await getDataFromTable(con.CONFIG[con.AT_TABLE_WEARABLE_PLACEMENTS])
    return (records)
}

export const getAllPendingIssues = async () => {
    let records = await at_base(con.CONFIG[con.AT_TABLE_ISSUES]).select({
        filterByFormula: `${con.AT_STATUS} = '${con.AT_PENDING}'`
    }).all();

    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })
    records = arrayToDict(records, con.ID)

    return (records)
}

export const getAllReceivedSensorFiles = async (monthsAgo = 2) => {

    const startDate = subMonths(new Date(), monthsAgo);

    let records = await at_base(con.CONFIG[con.AT_TABLE_RECEIVED_SENSOR_FILES]).select({
        filterByFormula: `IS_AFTER({${con.AT_DATE_UPLOADED}}, '${formatISO(startDate)}')`
    }).all();

    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })

    records = arrayToDict(records, con.ID)

    return (records)

}

export const getPhoneBySerial = async (serial) => {
    let records = await at_base(con.CONFIG[con.AT_TABLE_PHONES]).select({
        filterByFormula: `${con.AT_SERIAL} = '${serial}'`
    }).all();
    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })

    records = arrayToDict(records, con.ID)

    return (records)
}


export const getReceivedSensorFilesBySerial = async (serial, monthsAgo = 2) => {
    const startDate = subMonths(new Date(), monthsAgo);

    let records = await at_base(con.CONFIG[con.AT_TABLE_RECEIVED_SENSOR_FILES]).select({
        filterByFormula: `AND(IS_AFTER({${con.AT_DATE_UPLOADED}}, '${formatISO(startDate)}'),${con.AT_SERIAL} = '${serial}')`
    }).all();
    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })

    records = arrayToDict(records, con.ID)

    return (records)
}


export const getSensorBySerial = async (serial) => {
    let records = await at_base(con.CONFIG[con.AT_TABLE_SENSORS]).select({
        filterByFormula: `${con.AT_SERIAL} = '${serial}'`
    }).all();
    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })

    records = arrayToDict(records, con.ID)

    return (records)
}

export const updateAccountLastSynched = async (email, lastSynched) => {
    let records = await at_base(con.CONFIG[con.AT_TABLE_EMAILS]).select({
        filterByFormula: `{${con.AT_EMAIL}} = '${email}'`
    }).all();


    records = await Promise.all(records.map(async r =>
        await at_base(con.CONFIG[con.AT_TABLE_EMAILS]).update(r.id, { [con.AT_LAST_UPLOADED]: lastSynched })
    ))


    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })
    records = arrayToDict(records, con.ID)

    return (records)


}


export const exportParticipantsTable = async () => {
    const cols = [con.AT_PUBLIC_ID, con.AT_ACTIVE, con.AT_SEWA_ID, con.AT_TYPE, con.AT_NAME, con.AT_LAST_NAME, con.AT_PHONE_NUMBER, con.AT_JOINED_DATE, con.AT_AREA, con.AT_NOTES]

    let records = await at_base(con.CONFIG[con.AT_TABLE_PARTICIPANTS]).select({ view: con.AT_VIEW_PARTICIPANTS_PUBLIC }).all();
    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })

    // filters
    records = records.map(r => filterObjectByKeys(r, cols))


    // Exports to Excel
    const now = new Date()
    exportToExcel(records, `Participants SEWA ${format(now, "yyyy-MM-d")}.xlsx`, cols)

    return true

}


export const logBatteryChange = async (serial, date, time) => {

    let records = await at_base(con.CONFIG[con.AT_TABLE_SENSORS]).select({
        filterByFormula: `{${con.AT_SERIAL}} = '${serial}'`
    }).all();


    // Update
    let dateString = `${date} ${time}`

    records = await Promise.all(records.map(async r => await at_base(con.CONFIG[con.AT_TABLE_SENSORS]).update(r.id, { [con.AT_LATEST_BATTERY_CHANGE]: dateString })))


    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })
    records = arrayToDict(records, con.ID)


    return (records)



}


export const markIssueAsDone = async (issue, noteText) => {

    let newText = issue[con.AT_NOTES] === undefined || issue[con.AT_NOTES] === null ? "" : `${issue[con.AT_NOTES]}\n`

    if (!isNull(noteText))
        newText = `${newText}${nowInIndia()}: ${noteText}`

    let newIssue = await at_base(con.CONFIG[con.AT_TABLE_ISSUES]).update(issue[con.ID], {
        [con.AT_STATUS]: con.AT_DONE,
        [con.AT_DATE_SOLVED]: nowInIndia(),
        [con.AT_NOTES]: newText
    })

    let records = [newIssue]

    records = records.map((r) => { return ({ ...r.fields, [con.ID]: r.id, [con.KEY]: r.id }) })
    records = arrayToDict(records, con.ID)


    return (records)



}





