// Data Manipulation Functions
import { addDays, eachDayOfInterval, parseISO, startOfDay } from 'date-fns';
import { filterObject } from '../hooks/GeneralStoreHooks';
import * as con from './../Constants';
import * as dt_fun from './dateFunctions';
import * as ds_fun from './devicesStatusFunctions';
import { getExpiryDaysByBrand } from './sensorFunctions';



// Only SEWA participants
export const filterOnlySEWA = ({ allParticipants }) => {
    if (allParticipants === null)
        return null

    let participants = filterObject(allParticipants, pa => pa[con.AT_TYPE] === "SEWA" && !(pa[con.AT_PUBLIC_ID].toUpperCase().includes("TEST")))
    return participants
}

export const filterOnlyActive = ({ participants }) => {
    if (participants === null)
        return null
    let newParticipants = filterObject(participants, pa => pa[con.AT_ACTIVE] === "Yes")
    return newParticipants
}


export const attachSensorPlacement = ({ participants, sensors, sensorPlacements }) => {
    if (participants === null || sensors === null || sensorPlacements === null ||
        participants === undefined || sensors === undefined || sensorPlacements === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[con.AT_HAS_ACTIVE_SENSORS] = false
        par[con.AT_TOTAL_ACTIVE_SENSORS] = 0
        if (con.AT_SENSOR_PLACEMENTS in par) {
            par[con.AT_SENSOR_PLACEMENTS] = par[con.AT_SENSOR_PLACEMENTS].map(id => {
                return ({ ...sensorPlacements[id], [con.AT_SENSOR]: { ...sensors[sensorPlacements[id][con.AT_SENSOR]] } })
            })
            

            par[con.AT_ACTIVE_SENSORS] = [] 
            par[con.AT_SENSOR_PLACEMENTS].filter(sp => sp[con.AT_ACTIVE] === con.YES).forEach(sp => {
                let s = sp[con.AT_SENSOR]
                s[con.AT_START_DATE] = sp[con.AT_START_DATE]
                par[con.AT_ACTIVE_SENSORS].push(s)
            })
  
            par[con.AT_HAS_ACTIVE_SENSORS] = par[con.AT_ACTIVE_SENSORS].length > 0
            par[con.AT_TOTAL_ACTIVE_SENSORS] = par[con.AT_ACTIVE_SENSORS].length
           
        }

    })

    return participants

}

export const attachPhonePlacement = ({ participants, phones, phonePlacement }) => {
    if (participants === null || phones === null || phonePlacement === null ||
        participants === undefined || phones === undefined || phonePlacement === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[con.AT_HAS_ACTIVE_PHONES] = false
        if (con.AT_PHONE_PLACEMENTS in par) {
            par[con.AT_PHONE_PLACEMENTS] = par[con.AT_PHONE_PLACEMENTS].map(id => {
                return ({ ...phonePlacement[id], [con.AT_PHONE]: { ...phones[phonePlacement[id][con.AT_PHONE]] } })
            })
            
            par[con.AT_HAS_ACTIVE_PHONES] = par[con.AT_PHONE_PLACEMENTS].some(s => s[con.AT_ACTIVE] === con.YES)
            par[con.AT_ACTIVE_PHONE] = null
            if(par[con.AT_HAS_ACTIVE_PHONES])
                par[con.AT_ACTIVE_PHONE] = par[con.AT_PHONE_PLACEMENTS].filter(pp => pp[con.AT_ACTIVE] === con.YES)[0][con.AT_PHONE]
                  
        }

    })

    return participants

}


export const attachWearablePlacement = ({ participants, wearables, wearablePlacement }) => {
    if (participants === null || wearables === null || wearablePlacement === null ||
        participants === undefined || wearables === undefined || wearablePlacement === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[con.AT_HAS_ACTIVE_WEARABLES] = false
        if (con.AT_WEARABLE_PLACEMENT in par) {
            par[con.AT_WEARABLE_PLACEMENT] = par[con.AT_WEARABLE_PLACEMENT].map(id => {
                return ({ ...wearablePlacement[id], [con.AT_WEARABLE]: { ...wearables[wearablePlacement[id][con.AT_WEARABLE]] } })
            })

            par[con.AT_HAS_ACTIVE_WEARABLES] = par[con.AT_WEARABLE_PLACEMENT].some(s => s[con.AT_ACTIVE] === con.YES)
            par[con.AT_ACTIVE_WEARABLE] = null
            if(par[con.AT_HAS_ACTIVE_WEARABLES])
                par[con.AT_ACTIVE_WEARABLE] = par[con.AT_WEARABLE_PLACEMENT].filter(wp => wp[con.AT_ACTIVE] === con.YES)[0] 
        
        }

    })

    return participants

}


export const attachReceivedSensorFiles = ({ participants, receivedSensorFiles }) => {
    if (participants === null || receivedSensorFiles === null ||
        participants === undefined || receivedSensorFiles === undefined)
        return participants


    Object.values(participants).forEach(par => {
        if(con.AT_SENSOR_PLACEMENTS in par)
        {
            par[con.AT_SENSOR_PLACEMENTS].forEach(sp => {
                
                if(con.AT_RECEIVED_SENSOR_FILES in sp[con.AT_SENSOR])
                    sp[con.AT_SENSOR][con.AT_RECEIVED_SENSOR_FILES] = sp[con.AT_SENSOR][con.AT_RECEIVED_SENSOR_FILES].filter(rsk => rsk in receivedSensorFiles).map(rsk => receivedSensorFiles[rsk]) 
                else
                    sp[con.AT_SENSOR][con.AT_RECEIVED_SENSOR_FILES] = []

            })
        }
              
    })

    return participants

}



export const attachIssuesToParticipant = ({ participants, issues }) => {
    if (participants === null || issues === null ||
        participants === undefined || issues === undefined)
        return participants


    Object.values(participants).forEach(par => {
        if(con.AT_ISSUES in par)
        {
            par[con.AT_ISSUES] = par[con.AT_ISSUES].filter(i => i in issues).map(i => issues[i])
            par[con.AT_GROUND_TEAM_ISSUES] = par[con.AT_ISSUES].filter(i => i[con.AT_RESPONSIBLE] === con.AT_GROUND_TEAM)                 
        }
            
        else
        {
            par[con.AT_ISSUES] = []
            par[con.AT_GROUND_TEAM_ISSUES] = []
        }
            
              
    })

    return participants

}



export const computeDaysUntilExpiry = ({ participants }) => {

    if (participants === null)
        return (participants)

    const now = new Date()

    Object.values(participants).forEach((par, i) => {
        par[con.AT_DAYS_UNTIL_EXPIRE] = null

        if (con.AT_SENSOR_PLACEMENTS in par) {
            let arr = par[con.AT_SENSOR_PLACEMENTS].filter(sp => sp[con.AT_ACTIVE] === con.YES).map(sp => {
                let sns = sp[con.AT_SENSOR]
                let expDays = getExpiryDaysByBrand(sns[con.AT_BRAND])

                let daysUntilExpire = expDays - dt_fun.getDaysBetweenDates(sp[con.AT_START_DATE], now)
                if (sns !== undefined && con.AT_LATEST_RECORD in sns)
                    daysUntilExpire = Math.max(daysUntilExpire, expDays - dt_fun.getDaysBetweenDates(sns[con.AT_LATEST_RECORD], now))

                return daysUntilExpire

            })
            

            par[con.AT_DAYS_UNTIL_EXPIRE] = Math.min(...arr)

        }

    })

    return participants


}



export const computeDaysSinceLastUpload = ({ participants }) => {

    if (participants === null)
        return (participants)

    const now = new Date()

    Object.values(participants).forEach((par, i) => {
        par[con.AT_DAYS_SINCE_LAST_UPLOAD] = null
        par[con.AT_LATEST_COLLECTION_DATE] = new Date(par[con.AT_DATA_START])
        if (con.AT_SENSOR_PLACEMENTS in par) {

            // Days since last Upload
            let arr = par[con.AT_SENSOR_PLACEMENTS].filter(sp => sp[con.AT_ACTIVE] === con.YES).map(sp => {
                let sns = sp[con.AT_SENSOR]

                let daysSinceLastUpload = dt_fun.getDaysBetweenDates(sp[con.AT_START_DATE], now)
                if (sns !== undefined && con.AT_LAST_UPLOADED in sns)
                    daysSinceLastUpload = Math.min(daysSinceLastUpload, dt_fun.getDaysBetweenDates(sns[con.AT_LAST_UPLOADED], now))

                return daysSinceLastUpload

            })

            par[con.AT_DAYS_SINCE_LAST_UPLOAD] = Math.min(...arr)

            // Last collection Date
            let arrDates = par[con.AT_SENSOR_PLACEMENTS].filter(sp => sp[con.AT_ACTIVE] === con.YES).map(sp => {
                let sns = sp[con.AT_SENSOR]

                let lastCollectionDate = new Date(sp[con.AT_START_DATE])
                if (sns !== undefined && con.AT_LAST_UPLOADED in sns)
                    lastCollectionDate = Math.max(lastCollectionDate, new Date(sns[con.AT_LAST_UPLOADED]))

                return lastCollectionDate

            })

            par[con.AT_LATEST_COLLECTION_DATE] = Math.max(...arrDates)

        }

    })

    return participants


}



export const computeDeviceCollectionStatus = ({ participants }) => {

    if (participants === null)
        return (participants)



    Object.values(participants).forEach((par, i) => {
        
        // Intake Survey
        let [intakeStatus, intakeMessage] = ds_fun.getIntakeSurveyStatus(par[con.AT_INTAKE_STATUS]) 

        par[con.AT_INTAKE_SURVEY] = {
            [con.AT_DEVICE_COLLECTION_STATUS] : intakeStatus,
            [con.AT_DEVICE_COLLECTION_MESSAGE] : intakeMessage
        }

        // Phone
        if(par[con.AT_HAS_ACTIVE_PHONES])
        {
            // if(par[con.AT_SEWA_ID] === "20220331000367")
            //     console.log("YES")
                
            let [status, message] = ds_fun.getPhonePlacementStatus(par[con.AT_ACTIVE_PHONE][con.AT_LAST_UPLOADED], par[con.AT_LATEST_COLLECTION_DATE]) 
            par[con.AT_ACTIVE_PHONE][con.AT_DEVICE_COLLECTION_STATUS] = status
            par[con.AT_ACTIVE_PHONE][con.AT_DEVICE_COLLECTION_MESSAGE] = message

        }

        // Wearable
        if(par[con.AT_HAS_ACTIVE_WEARABLES])
        {
            // if(par[con.AT_SEWA_ID] === "20220331000367")
            //     console.log("YES")
                
            let [status, message] = ds_fun.getWearablePlacementStatus(par[con.AT_ACTIVE_WEARABLE][con.AT_LAST_UPLOADED], par[con.AT_LATEST_COLLECTION_DATE]) 
            par[con.AT_ACTIVE_WEARABLE][con.AT_DEVICE_COLLECTION_STATUS] = status
            par[con.AT_ACTIVE_WEARABLE][con.AT_DEVICE_COLLECTION_MESSAGE] = message

        }

        // Sensors
        if(par[con.AT_HAS_ACTIVE_SENSORS])
        {

            
            // if(par[con.AT_SEWA_ID] === "20240226038551")
            //     console.log("YES")

            par[con.AT_SENSOR_PLACEMENTS].filter(sp => sp[con.AT_ACTIVE] === con.YES).forEach(sp => {

                // if(sp[con.AT_SENSOR][con.AT_SERIAL] === "SY51D40408730")
                //     console.log("Yes")

                let [status, message] = ds_fun.getSensorPlacementStatus(sp[con.AT_SENSOR][con.AT_BRAND], sp[con.AT_SENSOR][con.AT_MODEL], sp[con.AT_SENSOR][con.AT_RECEIVED_SENSOR_FILES], par[con.AT_LATEST_COLLECTION_DATE], sp[con.AT_START_DATE], sp[con.AT_SENSOR][con.AT_LATEST_BATTERY_CHANGE]) 
                sp[con.AT_SENSOR][con.AT_DEVICE_COLLECTION_STATUS] = status
                sp[con.AT_SENSOR][con.AT_DEVICE_COLLECTION_MESSAGE] = message


            })

        }
        
        // For all sensors
        let [sensorStatus, sensorMessage] = ds_fun.getUnifyingStatusAndMessageForSensors(par[con.AT_ACTIVE_SENSORS]) 
        par[con.AT_SENSORS_COLLECTION_STATUS] = sensorStatus
        par[con.AT_SENSORS_COLLECTION_MESSAGE] = sensorMessage 

        // For Participant
        let [participantStatus, participantMessage] = ds_fun.getUnifyingStatusAndMessageForParticipant(par) 
        par[con.AT_COLLECTION_STATUS] = participantStatus
        par[con.AT_COLLECTION_MESSAGE] = participantMessage 
                

    })

    return participants


}





export const computeParticipantsByDate = (participants) => {
    let summary = {}

    let minDate = null

    Object.values(participants).forEach((par, i) => {
        let start_date = startOfDay(parseISO(par[con.AT_JOINED_DATE])).getTime()

        if (!(start_date in summary))
            summary[start_date] = 0

        summary[start_date] += 1

        if (minDate === null)
            minDate = start_date
        else
            minDate = Math.min(start_date, minDate)


    })

    const dates = eachDayOfInterval({ start: new Date(minDate), end: addDays(new Date(), 1) });

    // Iterate over the dates and perform some action
    dates.forEach(date => {
        if (!(date.getTime() in summary))
            summary[date.getTime()] = 0
    });

    let arr = Object.keys(summary).map(k => { return ({ [con.PARTICIPANTS]: summary[k], [con.DATE]: parseInt(k) }) })
    arr.sort((a, b) => a[[con.DATE]] - b[[con.DATE]]);

    return arr

}