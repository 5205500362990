// Dataset Hooks
import { useEffect, useState } from "react"
import { fetchParticipantData, fetchPendingIssues, fetchPhoneData, fetchPhonePlacementData, fetchReceivedSensorFiles, fetchSensorData, fetchSensorHousePlacementData, fetchWearableData, fetchWearablePlacementData } from "../store/actions/datasetActions"
import { resetAllDatasets } from "../store/actions/generalStoreActions"
import * as con from "./../Constants"
import * as dm_fun from "./../utils/dataManipulationFunctions"
import { RefStoreValue } from "./GeneralStoreHooks"


export const refreshAllDatasets = () =>
{

    // Resets All Datasets
    resetAllDatasets()

    fetchParticipantData()
    fetchSensorData()
    fetchSensorHousePlacementData()
    fetchPhoneData()
    fetchPhonePlacementData()
    fetchWearableData()
    fetchWearablePlacementData()
    fetchReceivedSensorFiles()
    fetchPendingIssues()
}


export const RefParticipantsData = () => {
    const allParticipants = RefStoreValue(con.DATASET_PARTICIPANTS)
    const status = RefStoreValue(con.STATUS_DATASET_PARTICIPANTS)

    const [participants, setParticipants] = useState(() => null)

    useEffect(() => {

        setParticipants(dm_fun.filterOnlySEWA({ allParticipants }))
    }, [allParticipants])


    return ([status, participants])
}

export const RefParticipantsDeploymentData = () => {
    const allParticipants = RefStoreValue(con.DATASET_PARTICIPANTS)
    const status = RefStoreValue(con.STATUS_DATASET_PARTICIPANTS)

    const [participantsByDate, setParticipantsByDate] = useState(() => null)

    useEffect(() => {

        if (allParticipants !== null && allParticipants !== undefined) {
            let par = dm_fun.filterOnlySEWA({ allParticipants })
            par = dm_fun.filterOnlyActive({ participants: par })
            setParticipantsByDate(dm_fun.computeParticipantsByDate(par))
        }


    }, [allParticipants])


    return ([status, participantsByDate])
}

export const RefActiveParticipantsWithSensorData = () => {
    // Participants
    const allParticipants = RefStoreValue(con.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoreValue(con.STATUS_DATASET_PARTICIPANTS)

    // Sensors
    const sensors = RefStoreValue(con.DATASET_SENSORS)
    const statusSensors = RefStoreValue(con.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const sensorPlacements = RefStoreValue(con.DATASET_SENSOR_HOUSE_PLACEMENT)
    const statusSensorPlacement = RefStoreValue(con.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)


    const [participants, setParticipants] = useState(() => null)
    const [status, setStatus] = useState(() => con.NOT_STARTED)


    useEffect(() => {

        if (allParticipants !== null && sensors !== null && sensorPlacements !== null) {
            let newParticipants = dm_fun.filterOnlySEWA({ allParticipants: structuredClone(allParticipants) })
            newParticipants = dm_fun.filterOnlyActive({ participants: newParticipants })
            newParticipants = dm_fun.attachSensorPlacement({ participants: newParticipants, sensors: structuredClone(sensors), sensorPlacements: structuredClone(sensorPlacements) })
            newParticipants = dm_fun.computeDaysUntilExpiry({ participants: newParticipants })
            newParticipants = dm_fun.computeDaysSinceLastUpload({ participants: newParticipants })
            setParticipants(newParticipants)

        }

    }, [allParticipants, sensors, sensorPlacements])


    useEffect(() => {

        if (statusParticipants === con.LOADING || statusSensors === con.LOADING || statusSensorPlacement === con.LOADING)
            setStatus(con.LOADING)
        else if (statusParticipants === con.ERROR || statusSensors === con.ERROR || statusSensorPlacement === con.ERROR)
            setStatus(con.LOADING)
        else if (statusParticipants === con.OK && statusSensors === con.OK && statusSensorPlacement === con.OK)
            setStatus(con.OK)


    }, [statusParticipants, statusSensors, statusSensorPlacement])



    return ([status, participants])
}



export const RefActiveParticipantsWithAllPlacements = () => {

    // Participants
    const allParticipants = RefStoreValue(con.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoreValue(con.STATUS_DATASET_PARTICIPANTS)

    // Sensors
    const sensors = RefStoreValue(con.DATASET_SENSORS)
    const statusSensors = RefStoreValue(con.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const sensorPlacements = RefStoreValue(con.DATASET_SENSOR_HOUSE_PLACEMENT)
    const statusSensorPlacement = RefStoreValue(con.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)

    // Wearables
    const wearables = RefStoreValue(con.DATASET_WEARABLES)
    const statusWearables = RefStoreValue(con.STATUS_DATASET_WEARABLES)

    // Wearable Placement
    const wearablesPlacements = RefStoreValue(con.DATASET_WEARABLE_PLACEMENT)
    const statusWearablesPlacement = RefStoreValue(con.STATUS_DATASET_WEARABLE_PLACEMENT)

    // Phones
    const phones = RefStoreValue(con.DATASET_PHONES)
    const statusPhones = RefStoreValue(con.STATUS_DATASET_PHONES)

    // Phone Placement
    const phonePlacements = RefStoreValue(con.DATASET_PHONE_PLACEMENT)
    const statusPhonePlacement = RefStoreValue(con.STATUS_DATASET_PHONE_PLACEMENT)

    // Received Sensor Files
    const receivedSensorFiles = RefStoreValue(con.DATASET_RECEIVED_SENSOR_FILES)
    const statusReceivedSensorFiles = RefStoreValue(con.STATUS_DATASET_RECEIVED_SENSOR_FILES)

    // Received Sensor Files
    const issues = RefStoreValue(con.DATASET_ISSUES)
    const statusIssues = RefStoreValue(con.STATUS_DATASET_ISSUES)

    const [participants, setParticipants] = useState(() => null)
    const [status, setStatus] = useState(() => con.NOT_STARTED)


    useEffect(() => {

        if (allParticipants !== null &&
            sensors !== null &&
            sensorPlacements !== null &&
            wearables !== null &&
            wearablesPlacements !== null &&
            phones !== null &&
            phonePlacements !== null &&
            receivedSensorFiles !== null &&
            issues !== null) {
            let newParticipants = dm_fun.filterOnlySEWA({ allParticipants: structuredClone(allParticipants) })

            newParticipants = dm_fun.filterOnlyActive({ participants: newParticipants })
            newParticipants = dm_fun.attachIssuesToParticipant({ participants: newParticipants, issues : issues })
            newParticipants = dm_fun.attachSensorPlacement({ participants: newParticipants, sensors: structuredClone(sensors), sensorPlacements: structuredClone(sensorPlacements) })
            newParticipants = dm_fun.attachReceivedSensorFiles({ participants: newParticipants, receivedSensorFiles: structuredClone(receivedSensorFiles)})            
            newParticipants = dm_fun.attachPhonePlacement({ participants: newParticipants, phones: phones, phonePlacement: phonePlacements })
            newParticipants = dm_fun.attachWearablePlacement({ participants: newParticipants, wearables: wearables, wearablePlacement: wearablesPlacements })            
            newParticipants = dm_fun.computeDaysUntilExpiry({ participants: newParticipants })
            newParticipants = dm_fun.computeDaysSinceLastUpload({ participants: newParticipants })
            newParticipants = dm_fun.computeDeviceCollectionStatus({ participants: newParticipants })


            setParticipants(newParticipants)
            
        }

    }, [allParticipants, sensors, sensorPlacements, wearables, wearablesPlacements, phones, phonePlacements, receivedSensorFiles, issues])


    useEffect(() => {

        if (statusParticipants === con.LOADING ||
            statusSensors === con.LOADING ||
            statusSensorPlacement === con.LOADING ||
            statusWearables === con.LOADING ||
            statusWearablesPlacement === con.LOADING ||
            statusPhones === con.LOADING ||
            statusPhonePlacement === con.LOADING ||
            statusReceivedSensorFiles === con.LOADING ||
            statusIssues === con.LOADING)
            setStatus(con.LOADING)
        else if (statusParticipants === con.ERROR ||
            statusSensors === con.ERROR ||
            statusSensorPlacement === con.ERROR ||
            statusWearables === con.ERROR ||
            statusWearablesPlacement === con.ERROR ||
            statusPhones === con.ERROR ||
            statusPhonePlacement === con.ERROR ||
            statusReceivedSensorFiles === con.ERROR ||
            statusIssues === con.ERROR)
            setStatus(con.LOADING)
        else if (statusParticipants === con.OK &&
            statusSensors === con.OK &&
            statusSensorPlacement === con.OK &&
            statusWearables === con.OK &&
            statusWearablesPlacement === con.OK &&
            statusPhones === con.OK &&
            statusPhonePlacement === con.OK &&
            statusReceivedSensorFiles === con.OK && 
            statusIssues === con.OK)
            setStatus(con.OK)


    }, [statusParticipants, statusSensors, statusSensorPlacement, statusWearables, statusWearablesPlacement, statusPhones, statusPhonePlacement, statusReceivedSensorFiles, statusIssues])



    return ([status, participants])
}


