import * as con from '../Constants';
import axios from "axios";


export const getLastSync = async (requestId, token) => {
    

    const link = `https://api.fitbit.com/1/user/${requestId}/devices.json`;


      try {
        const response = await axios.get(link, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        


        if(response.status === 200)
        {
          let data = response.data
          

          if(data.length > 0 && "lastSyncTime" in data[0])
          {
                      
              const parsedDate = new Date(data[0]["lastSyncTime"]);

              if (isNaN(parsedDate)) 
                  return [con.OK, null ]
                  
              return [con.OK, data[0]["lastSyncTime"]]
            
          }
          else
            return [con.NOT_FOUND, null ]

        }
            
      } catch (error) {
        
        if (error.message === 'Network request failed')
            return ([con.NETWORK_ERROR, null])

        return ([con.ERROR, null])

      }
}