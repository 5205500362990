import React, { useEffect, useState } from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import * as con from "./../Constants"
import { Button, Input, Space, Table } from "antd";
import { Typography } from 'antd';
import { getColorOfExpiry, getColorOfLastUpload, getColorOfLostDays, getTextOfExpiry } from '../utils/generalFunctions';
import { formatDistanceGivenDays, formatDistanceShort } from '../utils/dateFunctions';
import { SymbolForStatus } from '../utils/devicesStatusFunctions';
import { ArrowRightOutlined } from '@ant-design/icons';
import { setValue } from '../store/actions/generalStoreActions';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import LoadingDatasets from '../components/LoadingDatasets';

const { Text, Title } = Typography;

function DashboardExpiryParticipants() {

  // Navigation
  const setCurrentPage = RefSetCurrentPage()

  const goToParticipant = (id) =>
  {
    setValue(con.SELECTED_PARTICIPANT, id)
    setCurrentPage(con.PAGE_DASHBOARD_SINGLE_COLLECTION_STATUS  )
  }


  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const [participantArray, setParticipantArray] = useState([])


  const [inputValue, setInputValue] = useState(() => null)
  const [filterText, setFilterText] = useState(() => null)

  // Status
  const [isTableReady, setIsTableReady] = useState(false)

  useEffect(() => {
    
    setIsTableReady(status !== con.NOT_STARTED && status !== con.LOADING && participants !== null)
  }, [status, participants])
  

  useEffect(() => {

    if (participants !== null) {
      let parArray = Object.values(participants)

      if (filterText !== null && filterText !== undefined && filterText !== "")
          parArray = parArray.filter(ob => ob[con.AT_PUBLIC_ID].toUpperCase().includes(filterText.toUpperCase()))
      
        setParticipantArray(parArray)
    }

  }, [participants, filterText])


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const columns = [
    {
      title: <p>Collection<br/>Status</p>,
      dataIndex: con.AT_COLLECTION_STATUS,
      key: con.AT_COLLECTION_STATUS,
      align: 'center',
      sorter: (a, b) => a[con.AT_COLLECTION_STATUS].localeCompare(b[con.AT_COLLECTION_STATUS]),
      render: (val, p) => <SymbolForStatus status={p[con.AT_COLLECTION_STATUS]} message={p[con.AT_COLLECTION_MESSAGE]}/>
    },
    {
      title: 'Names',
      dataIndex: con.AT_NAME,
      key: con.AT_NAME,
      align: 'center',
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfExpiry(record[con.AT_DAYS_UNTIL_EXPIRE]) }}>{record[con.AT_NAME] + " " + record[con.AT_LAST_NAME]}</p>
    },
    {
      title: ' SEWA Id ',
      dataIndex: con.AT_SEWA_ID,
      key: con.AT_SEWA_ID,
      align: 'center',
      render: (val, record) => <p style={{ fontSize : 12, textAlign: 'center', color: getColorOfExpiry(record[con.AT_DAYS_UNTIL_EXPIRE]) }}>{val}</p>
    },
    {
      title: 'Area',
      dataIndex: con.AT_AREA,
      key: con.AT_AREA,
      align: 'center',
      render: (val, record) => <p style={{ fontSize : 12, textAlign: 'center', color: getColorOfExpiry(record[con.AT_DAYS_UNTIL_EXPIRE]) }}>{val}</p>
    },
    {
      title: <p>Next Collection</p>,
      dataIndex: con.AT_DAYS_UNTIL_EXPIRE,
      key: con.AT_DAYS_UNTIL_EXPIRE,
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a[con.AT_DAYS_UNTIL_EXPIRE] - b[con.AT_DAYS_UNTIL_EXPIRE],
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfExpiry(record[con.AT_DAYS_UNTIL_EXPIRE]) }}>{getTextOfExpiry(val)}</p>

    },
    {
      title: <p>Last Collection</p>,
      dataIndex: con.AT_LATEST_COLLECTION_DATE,
      key: con.AT_LATEST_COLLECTION_DATE,
      align: 'center',
      sorter: (a, b) => a[con.AT_DAYS_SINCE_LAST_UPLOAD] - b[con.AT_DAYS_SINCE_LAST_UPLOAD],
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfLastUpload(record[con.AT_DAYS_SINCE_LAST_UPLOAD]) }}>{formatDistanceShort(val)}</p>

    },
    {
      title: <p>Lost Data</p>,
      dataIndex: con.AT_DAYS_UNTIL_EXPIRE,
      key: con.AT_DAYS_UNTIL_EXPIRE + "2",
      align: 'center',
      sorter: (a, b) => a[con.AT_DAYS_UNTIL_EXPIRE] - b[con.AT_DAYS_UNTIL_EXPIRE],
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfLostDays(record[con.AT_DAYS_UNTIL_EXPIRE]) }}>{val >= 0 ? "0 days" : formatDistanceGivenDays(val, false)}</p>

    },
    {
      title: <p>Sensor<br/>Status</p>,
      dataIndex: con.AT_HAS_ACTIVE_SENSORS,
      key: con.AT_HAS_ACTIVE_SENSORS,
      align: 'center',
      render: (val, record) => val ? <SymbolForStatus status={record[con.AT_SENSORS_COLLECTION_STATUS]} message={record[con.AT_SENSORS_COLLECTION_MESSAGE]}/> : <p></p>

    },
    {
      title: <p>Location Tracker<br/>Status</p>,
      dataIndex: con.AT_HAS_ACTIVE_PHONES,
      key: con.AT_HAS_ACTIVE_PHONES,
      align: 'center',
      render: (val, record) => val ? <SymbolForStatus status={record[con.AT_ACTIVE_PHONE][con.AT_DEVICE_COLLECTION_STATUS]} message={record[con.AT_ACTIVE_PHONE][con.AT_DEVICE_COLLECTION_MESSAGE]}/> : <p></p>

    },
    {
      title: <p>Wearable<br/>Status</p>,
      dataIndex: con.AT_HAS_ACTIVE_WEARABLES,
      key: con.AT_HAS_ACTIVE_WEARABLES,
      align: 'center',
      render: (val, record) => val ? <SymbolForStatus status={record[con.AT_ACTIVE_WEARABLE][con.AT_DEVICE_COLLECTION_STATUS]} message={record[con.AT_ACTIVE_WEARABLE][con.AT_DEVICE_COLLECTION_MESSAGE]}/> : <p></p>

    },
    {
      title: <p>Intake<br/>Survey<br/>Status</p>,
      dataIndex: con.AT_INTAKE_STATUS,
      key: con.AT_INTAKE_STATUS,
      align: 'center',
      render: (val, record) => val ? <SymbolForStatus status={record[con.AT_INTAKE_SURVEY][con.AT_DEVICE_COLLECTION_STATUS]} message={record[con.AT_INTAKE_SURVEY][con.AT_DEVICE_COLLECTION_MESSAGE]}/> : <p></p>

    },
    {
      title: <p>Pending<br/>Issues</p>,
      dataIndex: con.AT_GROUND_TEAM_ISSUES,
      key: con.AT_GROUND_TEAM_ISSUES,
      align: 'center',
      render: (val, record) => <p>{val === null || val === undefined ? 0 : val.filter(i => i[con.AT_STATUS] === con.AT_PENDING).length}</p>

    },
    {
      title: 'Go To',
      dataIndex: con.ID,
      key: con.ID,
      align: 'center',
      render: (val) => <Button type="primary" onClick={() => goToParticipant(val)}><ArrowRightOutlined/></Button>     ,
    },
  ];

  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant Data About to Expire`}</Title>
      <Space direction="vertical" >
        <Space direction="horizontal" >
          <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh All</Button>
          <Input placeholder="Filter" value={inputValue} onChange={handleInputChange} />
          <Button type="primary" onClick={() => setFilterText(inputValue)}>Filter</Button>
          <Button type="primary" onClick={() => {setInputValue(null);setFilterText(null)}} danger>Clear</Button>
        </Space>
        <Space direction="horizontal" size={"large"}>
            <Space direction="horizontal" >
                <SymbolForStatus status={con.DEVICE_STATUS_OK} message={con.MESSAGE_DATA_UP_TO_DATE}/>
                <Text style={{fontSize : "0.8em", color : "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[con.AT_COLLECTION_STATUS] === con.DEVICE_STATUS_OK).length}</Text>
            </Space>
            <Space direction="horizontal" >
                <SymbolForStatus status={con.DEVICE_STATUS_WAITING_COLLECTION} message={con.MESSAGE_WAITING_FOR_COLLECTION}/>
                <Text style={{fontSize : "0.8em", color : "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[con.AT_COLLECTION_STATUS] === con.DEVICE_STATUS_WAITING_COLLECTION).length}</Text>
            </Space>
            <Space direction="horizontal" >
                <SymbolForStatus status={con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA} message={con.MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA}/>
                <Text style={{fontSize : "0.8em", color : "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[con.AT_COLLECTION_STATUS] === con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA).length}</Text>
            </Space>            
            <Space direction="horizontal" >
                <SymbolForStatus status={con.DEVICE_STATUS_ERROR_IN_COLLECTION} message={con.MESSAGE_ERROR_IN_COLLECTION}/>
                <Text style={{fontSize : "0.8em", color : "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[con.AT_COLLECTION_STATUS] === con.DEVICE_STATUS_ERROR_IN_COLLECTION).length}</Text>
            </Space>
        </Space>

      </Space>
      
      
      <div style={{ marginTop: "20px" }}>
        {
          !isTableReady
            ? <LoadingDatasets />
            : status === con.ERROR
              ? <div>Error</div>
              : <Table dataSource={participantArray} columns={columns} size="small" scroll={{ y: 600 }} pagination={{ pageSize: 100 }} />
        }
      </div>
    </div>
  )
}

export default DashboardExpiryParticipants