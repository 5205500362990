import React from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import * as con from "../Constants"
import { Button, Space } from "antd";
import { Typography } from 'antd';
import { RefStoreValue } from '../hooks/GeneralStoreHooks';
import { useNavigate } from 'react-router-dom';
import { DataCollectionStatusCard } from './DashboardCollectionStatus';
import LoadingDatasets from '../components/LoadingDatasets';


const { Title } = Typography;


function DashboardSingleCollectionStatus() {

  const navigate = useNavigate();

  
  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const selectedParticipantId = RefStoreValue(con.SELECTED_PARTICIPANT)

  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant's Data Collection Status`}</Title>
      <Space direction="horizontal" >
        <Button type="primary" onClick={() => navigate(-1)}>Back</Button>
        <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh All</Button>
      </Space>

      <div style={{ marginTop: "5%" }}>
        {
          status === con.NOT_STARTED || status === con.LOADING || participants === null
            ? <LoadingDatasets />
            : status === con.ERROR
              ? <div>Error</div>
              : <div>
                {
                    selectedParticipantId in participants
                    ? <DataCollectionStatusCard key={participants[selectedParticipantId][con.ID]} participant={participants[selectedParticipantId]} />
                    : <Title level={3}>Participant Not Found</Title>
                    
                }
              </div>
        }
      </div>
    </div>
  )
}

export default DashboardSingleCollectionStatus