// Functions to determine a device status
import * as dt_fun from './dateFunctions';
import * as con from './../Constants';
import { BugOutlined, CheckCircleTwoTone, ClockCircleTwoTone, ClockCircleOutlined, QuestionCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';



// Constants
// For support between the dashboard and app (not great)
const START_DATE = con.AT_START_DATE
const END_DATE = con.AT_END_DATE
const DATE_UPLOADED = con.AT_DATE_UPLOADED



export const SymbolForStatus = ({ status, message }) => {

    switch (status) {
        case con.DEVICE_STATUS_OK:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={message}><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip>
        case con.DEVICE_STATUS_WAITING_COLLECTION:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={message}><ClockCircleOutlined /></Tooltip>
        case con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={message}><ClockCircleTwoTone twoToneColor="#e91822" /></Tooltip>
        case con.DEVICE_STATUS_ERROR_IN_COLLECTION:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={message}><WarningTwoTone twoToneColor="#e91822" /></Tooltip>
        case con.DEVICE_STATUS_HARDWARE_MALFUNCTION:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={message}><BugOutlined /></Tooltip>
        default:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={message}><QuestionCircleOutlined /></Tooltip>
    }

}




// Identical code to CH Manager App 
// --------------------------------------

const VISIT_COLLECTION_WINDOW_HOURS = 12
const WAITING_FOR_COLLECTION_WINDOW_DAYS = 10
const MAX_GAP_BETWEEN_FILES_DAYS = 1
const MAX_GAP_SINCE_COLLECTION_AND_LATEST_FILE_DAYS = 2
const MIN_ACCEPTABLE_EXPORT_WINDOW_DAYS = 17
const MAX_GAP_FORM_PLACEMENT_START_TO_EXPORT_DAYS = 1
const MAX_ENVIRONMENTAL_SENSOR_CAPACITY_DAYS = 20
const MAX_WEARABLE_CAPACITY_DAYS = 30

export const getIntakeSurveyStatus = (intakeStatus) => {

    if (intakeStatus.length === 0)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_NO_INTAKES]
    if (intakeStatus.length > 1)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_NO_INTAKES]

    const status = intakeStatus[0]

    switch (status) {
        case con.AT_COMPLETE:
            return [con.DEVICE_STATUS_OK, con.MESSAGE_INTAKE_COMPLETE]
        case con.AT_REQUIRED:
            return [con.DEVICE_STATUS_WAITING_COLLECTION, con.MESSAGE_INTAKE_WAITING]
        default:
            return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, "Status not recognized"];
    }
}

export const getPhonePlacementStatus = (latestUpload, latestHouseVisit) => {

    if (latestUpload === null || latestUpload === undefined)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_DATA_WAS_NOT_COLLECTED]

    let daysBetweenVisit = dt_fun.getDaysBetweenDates(latestHouseVisit, latestUpload)
    let hoursBetweenVisit = daysBetweenVisit * 24

    let daysSinceLastUpload = dt_fun.getDaysBetweenDates(latestUpload, new Date())

    // Data was not collected
    if (hoursBetweenVisit < -1 * VISIT_COLLECTION_WINDOW_HOURS)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_DATA_WAS_NOT_COLLECTED]


    // Waiting for Data collection
    if (daysSinceLastUpload >= WAITING_FOR_COLLECTION_WINDOW_DAYS)
        return [con.DEVICE_STATUS_WAITING_COLLECTION, con.MESSAGE_WAITING_FOR_COLLECTION]

    // Last time stamp was inside VISIT COLLECTION WINDOW or after the latest house visit
    return [con.DEVICE_STATUS_OK, con.MESSAGE_DATA_UP_TO_DATE]


}


export const getWearablePlacementStatus = (latestUpload, latestHouseVisit) => {
    let daysBetweenVisit = dt_fun.getDaysBetweenDates(latestHouseVisit, latestUpload)
    let hoursBetweenVisit = daysBetweenVisit * 24

    let daysSinceLastUpload = dt_fun.getDaysBetweenDates(latestUpload, new Date())

    // Data was not collected
    if (hoursBetweenVisit < -1 * VISIT_COLLECTION_WINDOW_HOURS)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_DATA_WAS_NOT_COLLECTED]


    // Waiting for Data collection
    if (daysSinceLastUpload >= WAITING_FOR_COLLECTION_WINDOW_DAYS) {
        if (daysSinceLastUpload > MAX_WEARABLE_CAPACITY_DAYS)
            return [con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, con.MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA]

        return [con.DEVICE_STATUS_WAITING_COLLECTION, con.MESSAGE_WAITING_FOR_COLLECTION]
    }



    // Last time stamp was inside VISIT COLLECTION WINDOW or after the latest house visit
    return [con.DEVICE_STATUS_OK, con.MESSAGE_DATA_UP_TO_DATE]


}


export const getSensorPlacementStatus = (brand, model, latestFiles, latestHouseVisit, placementStart, lastBatteryChange) => {

    if (brand === con.HOBO && model === con.U12_HOBO_MODEL) // Collection is handled by CEPT team. 
        return [con.DEVICE_STATUS_OK, con.MESSAGE_HANDLED_BY_CEPT]


    if (latestFiles.length === 0)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_NO_FILES_HAVE_BEEN_RECEIVED]

    // Computes latest export window
    // First sorts
    latestFiles = latestFiles.map(f => f)
    latestFiles.sort((f1, f2) => new Date(f2[END_DATE]) - new Date(f1[END_DATE]))

    //Extracts lastUpload
    let latestUpload = Math.max(...latestFiles.map(f => new Date(f[DATE_UPLOADED])))

    // Extracts start date and end date
    let endDate = new Date(latestFiles[0][END_DATE])
    let startDate = new Date(latestFiles[0][START_DATE])

    // Loop through the array
    for (let i = 1; i < latestFiles.length; i++) {

        // Checks for intersection
        let tempEndDate = new Date(latestFiles[i][END_DATE])
        if (((tempEndDate - startDate) / (1000 * 60 * 60 * 24)) >= -1 * MAX_GAP_BETWEEN_FILES_DAYS)
            startDate = Math.min(startDate, new Date(latestFiles[i][START_DATE]))
        else
            break
    }


    let daysBetweenVisit = dt_fun.getDaysBetweenDates(latestHouseVisit, latestUpload)
    let hoursBetweenVisit = daysBetweenVisit * 24

    let daysSinceLastUpload = dt_fun.getDaysBetweenDates(latestUpload, new Date())


    let exportWindow = dt_fun.getDaysBetweenDates(startDate, endDate)


    // Data was not collected
    if (hoursBetweenVisit < -1 * VISIT_COLLECTION_WINDOW_HOURS)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_DATA_WAS_NOT_COLLECTED]


    // Waiting for Data collection
    if (daysSinceLastUpload >= WAITING_FOR_COLLECTION_WINDOW_DAYS) {
        if (daysSinceLastUpload > MAX_ENVIRONMENTAL_SENSOR_CAPACITY_DAYS)
            return [con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, con.MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA]

        return [con.DEVICE_STATUS_WAITING_COLLECTION, con.MESSAGE_WAITING_FOR_COLLECTION]
    }



    // Assumes data was collected and will check for quality
    let daysBetweenEndDateAndVisit = dt_fun.getDaysBetweenDates(endDate, latestHouseVisit)
    let daysSinceDeployment = dt_fun.getDaysBetweenDates(placementStart, latestHouseVisit)
    let daysSinceLastBatteryChange = dt_fun.getDaysBetweenDates(lastBatteryChange, latestHouseVisit)

    // File not recent enough
    if (daysBetweenEndDateAndVisit > MAX_GAP_SINCE_COLLECTION_AND_LATEST_FILE_DAYS)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_SENSOR_FILE_NOT_RECENT_ENOUGH]

    // File not long enough
    if (exportWindow < MIN_ACCEPTABLE_EXPORT_WINDOW_DAYS) {
        if ((daysSinceDeployment - exportWindow) <= MAX_GAP_FORM_PLACEMENT_START_TO_EXPORT_DAYS)
            return [con.DEVICE_STATUS_OK, con.MESSAGE_PLACEMENT_JUST_STARTED]

        if ((daysSinceLastBatteryChange - exportWindow) <= MAX_GAP_FORM_PLACEMENT_START_TO_EXPORT_DAYS)
            return [con.DEVICE_STATUS_OK, con.MESSAGE_BATTERY_CHANGE_JUST_HAPPENED]


        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, con.MESSAGE_SENSOR_FILE_NOT_LONG_ENOUGH]

    }



    // Last time stamp was inside VISIT COLLECTION WINDOW or after the latest house visit
    return [con.DEVICE_STATUS_OK, con.MESSAGE_DATA_UP_TO_DATE]


}

export const getUnifyingStatusAndMessageForSensors = (sensors) => {

    if (sensors.length === 0)
        return [con.DEVICE_STATUS_OTHER, con.MESSAGE_NO_SENSORS_IN_BUNDLE]

    // All of the sensors are up to date
    if (sensors.every(s => s[con.AT_DEVICE_COLLECTION_STATUS] === con.DEVICE_STATUS_OK))
        return [con.DEVICE_STATUS_OK, sensors[0][con.AT_DEVICE_COLLECTION_MESSAGE]]

    // All of the sensors waiting for collection
    if (sensors.every(s => s[con.AT_DEVICE_COLLECTION_STATUS] === con.DEVICE_STATUS_WAITING_COLLECTION))
        return [con.DEVICE_STATUS_WAITING_COLLECTION, sensors[0][con.AT_DEVICE_COLLECTION_MESSAGE]]


    // At least one has an error
    if (sensors.some(s => s[con.AT_DEVICE_COLLECTION_STATUS] === con.DEVICE_STATUS_ERROR_IN_COLLECTION)) {
        let arrStrings = sensors.filter(s => s[con.AT_DEVICE_COLLECTION_STATUS] === con.DEVICE_STATUS_ERROR_IN_COLLECTION).map(s => `${s[con.AT_BRAND]} ${s[con.AT_SERIAL]}: ${s[[con.AT_DEVICE_COLLECTION_MESSAGE]]}`)
        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, arrStrings.join('\n')]
    }

    // At least one is loosing days
    if (sensors.some(s => s[con.AT_DEVICE_COLLECTION_STATUS] === con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)) {
        let arrStrings = sensors.filter(s => s[con.AT_DEVICE_COLLECTION_STATUS] === con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA).map(s => `${s[con.AT_BRAND]} ${s[con.AT_SERIAL]}: ${s[[con.AT_DEVICE_COLLECTION_MESSAGE]]}`)
        return [con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, arrStrings.join('\n')]
    }


    // There are no sensors with error and at least no not up to date and not waiting for collection
    let arrStrings = sensors.filter(s => s[con.AT_DEVICE_COLLECTION_STATUS] !== con.DEVICE_STATUS_OK && s[con.AT_DEVICE_COLLECTION_STATUS] !== con.DEVICE_STATUS_WAITING_COLLECTION && s[con.AT_DEVICE_COLLECTION_STATUS] !== con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA).map(s => `${s[con.AT_BRAND]} ${s[con.AT_SERIAL]}: ${s[[con.AT_DEVICE_COLLECTION_MESSAGE]]}`)
    return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, arrStrings.join('\n')]


}


export const getUnifyingStatusAndMessageForParticipant = (participant) => {
    if (!participant[con.AT_HAS_ACTIVE_SENSORS] || !participant[con.AT_HAS_ACTIVE_PHONES] || !participant[con.AT_HAS_ACTIVE_WEARABLES])
        return [con.DEVICE_STATUS_OTHER, con.MESSAGE_INCOMPLETE_DEPLOYMENT]

    let sensorStatus = participant[con.AT_SENSORS_COLLECTION_STATUS]
    let sensorMessage = participant[con.AT_SENSORS_COLLECTION_MESSAGE]
    let phoneStatus = participant[con.AT_ACTIVE_PHONE][con.AT_DEVICE_COLLECTION_STATUS]
    let phoneMessage = participant[con.AT_ACTIVE_PHONE][con.AT_DEVICE_COLLECTION_MESSAGE]
    let wearableStatus = participant[con.AT_ACTIVE_WEARABLE][con.AT_DEVICE_COLLECTION_STATUS]
    let wearableMessage = participant[con.AT_ACTIVE_WEARABLE][con.AT_DEVICE_COLLECTION_MESSAGE]
    let intakeSurveyStatus = participant[con.AT_INTAKE_SURVEY][con.AT_DEVICE_COLLECTION_STATUS]
    let intakeSurveyMessage = participant[con.AT_INTAKE_SURVEY][con.AT_DEVICE_COLLECTION_MESSAGE]

    let status = [sensorStatus, phoneStatus, wearableStatus, intakeSurveyStatus]


    // All of the status are up to date
    if (status.every(s => s === con.DEVICE_STATUS_OK))
        return [con.DEVICE_STATUS_OK, con.MESSAGE_DATA_UP_TO_DATE]

    // All of the status are up to date or waiting waiting for collection.
    if (status.filter(s => s !== con.DEVICE_STATUS_OK).every(s => s === con.DEVICE_STATUS_WAITING_COLLECTION))
        return [con.DEVICE_STATUS_WAITING_COLLECTION, con.MESSAGE_WAITING_FOR_COLLECTION]

    // At least one has an error
    if (status.some(s => s === con.DEVICE_STATUS_ERROR_IN_COLLECTION)) {
        let arrStrings = []
        if (sensorStatus === con.DEVICE_STATUS_ERROR_IN_COLLECTION)
            arrStrings.push(`${con.DEVICE_SENSORS}: ${sensorMessage}`)
        if (phoneStatus === con.DEVICE_STATUS_ERROR_IN_COLLECTION)
            arrStrings.push(`${con.DEVICE_PHONE}: ${phoneMessage}`)
        if (wearableStatus === con.DEVICE_STATUS_ERROR_IN_COLLECTION)
            arrStrings.push(`${con.DEVICE_WEARABLE}: ${wearableMessage}`)
        if (intakeSurveyStatus === con.DEVICE_STATUS_ERROR_IN_COLLECTION)
            arrStrings.push(`${con.AT_INTAKE_SURVEY}: ${intakeSurveyMessage}`)


        return [con.DEVICE_STATUS_ERROR_IN_COLLECTION, arrStrings.join('\n')]
    }

    // At least one is loosing days
    if (status.some(s => s === con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)) {
        let arrStrings = []
        if (sensorStatus === con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)
            arrStrings.push(`${con.DEVICE_SENSORS}: ${sensorMessage}`)
        if (phoneStatus === con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)
            arrStrings.push(`${con.DEVICE_PHONE}: ${phoneMessage}`)
        if (wearableStatus === con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)
            arrStrings.push(`${con.DEVICE_WEARABLE}: ${wearableMessage}`)

        return [con.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, arrStrings.join('\n')]
    }


    return [con.DEVICE_STATUS_OTHER, ""]

}


