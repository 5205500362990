
// General store actions
import store from '../store';
import * as con from "./../../Constants"

export const setValue = (id, val) =>
{
    store.dispatch({
        type : con.SET_VALUE,
        payload : {[con.ID] : id, [con.VALUE] : val}
    })    

}


export const updateObjectValue = (id, newVal) =>
{
    store.dispatch({
        type : con.UPDATE_OBJECT_VALUE,
        payload : {[con.ID] : id, [con.VALUE] : newVal}
    })    

}


export const resetAllDatasets = () =>
{
    store.dispatch({
        type : con.RESET_ALL_DATASETS,
        payload : {}
    })    

}
