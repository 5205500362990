// Dataset Actions
import { setValue, updateObjectValue } from './generalStoreActions';
import * as con from "./../../Constants"
import * as air_fun from "./../../utils/airtableFunctions"


export const fetchParticipantData = async () => {
    fetchData(con.STATUS_DATASET_PARTICIPANTS, con.DATASET_PARTICIPANTS, air_fun.getAllParticipants)
}

export const fetchSensorData = async () => {
    fetchData(con.STATUS_DATASET_SENSORS, con.DATASET_SENSORS, air_fun.getAllSensors)
}

export const fetchSensorHousePlacementData = async () => {
    fetchData(con.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT, con.DATASET_SENSOR_HOUSE_PLACEMENT, air_fun.getAllSensorHousePlacement)
}

export const fetchHouseData = async () => {
    fetchData(con.STATUS_DATASET_HOUSES, con.DATASET_HOUSES, air_fun.getAllHouses)
}

export const fetchParticipantPlacementData = async () => {
    fetchData(con.STATUS_DATASET_PARTICIPANT_PLACEMENT, con.DATASET_PARTICIPANT_PLACEMENT, air_fun.getAllParticipantPlacement)
}

export const fetchWearableData = async () => {
    fetchData(con.STATUS_DATASET_WEARABLES, con.DATASET_WEARABLES, air_fun.getAllWearables)
}

export const fetchWearablePlacementData = async () => {
    fetchData(con.STATUS_DATASET_WEARABLE_PLACEMENT, con.DATASET_WEARABLE_PLACEMENT, air_fun.getAllWearablePlacements)
}


export const fetchPhoneData = async () => {
    fetchData(con.STATUS_DATASET_PHONES, con.DATASET_PHONES, air_fun.getAllPhones)
}

export const fetchPhonePlacementData = async () => {
    fetchData(con.STATUS_DATASET_PHONE_PLACEMENT, con.DATASET_PHONE_PLACEMENT, air_fun.getAllPhonesPlacement)
}

export const fetchReceivedSensorFiles = async () => {
    fetchData(con.STATUS_DATASET_RECEIVED_SENSOR_FILES, con.DATASET_RECEIVED_SENSOR_FILES, air_fun.getAllReceivedSensorFiles)
}

export const fetchPendingIssues = async () => {
    fetchData(con.STATUS_DATASET_ISSUES, con.DATASET_ISSUES, air_fun.getAllPendingIssues)
}


export const fetchData = async (status_id, dataset_id, extractionMethod) => {

    setValue(status_id, con.LOADING)

    try {

        const records = await extractionMethod()

        setValue(dataset_id, records)
        setValue(status_id, con.OK)


    } catch (error) {
        console.log(error)
        setValue(status_id, con.ERROR)
    }

}


export const updateSinglePhone = async (serial) =>
{

    try {

        let newRecords = await air_fun.getPhoneBySerial(serial)
        updateObjectValue(con.DATASET_PHONES, newRecords)

        return newRecords

    } catch (error) {
        console.log(error)
    }
}

export const updateSingleSensorFiles = async (serial) =>
{

    try {

        // Update received sensor files
        let newRecords = await air_fun.getReceivedSensorFilesBySerial(serial)
        updateObjectValue(con.DATASET_RECEIVED_SENSOR_FILES, newRecords)
        // Update sensor
        let newSensorRecord = await air_fun.getSensorBySerial(serial)
        updateObjectValue(con.DATASET_SENSORS, newSensorRecord)

        return newRecords

    } catch (error) {
        console.log(error)
    }
}



export const updateAccountWithLastSynched = async (token, lastSynched) =>
{

    try {

        // Update received sensor files
        let newRecords = await air_fun.updateAccountLastSynched(token, lastSynched)
        updateObjectValue(con.DATASET_WEARABLE_PLACEMENT, newRecords)
      
        return newRecords

    } catch (error) {
        console.log(error)
    }
}


export const logBatteryChange = async(serial, date, time) =>
{
    try {

        // Update received sensor files
        let records = await air_fun.logBatteryChange(serial, date, time)
        updateObjectValue(con.DATASET_SENSORS, records)
      
        return records

    } catch (error) {
        console.log(error)
    }
}


export const markIssueAsDone = async(issue, noteText) =>
{
    try {

        // Update received sensor files
        let records = await air_fun.markIssueAsDone(issue, noteText)
        updateObjectValue(con.DATASET_ISSUES, records)
      
        return records

    } catch (error) {
        console.log(error)
    }

}