import { CheckCircleTwoTone, QuestionCircleOutlined, WarningTwoTone } from '@ant-design/icons'
import React from 'react'
import * as con from "./../Constants"
import Title from 'antd/es/typography/Title';
import { Spin } from 'antd';
import { RefStoreValue } from '../hooks/GeneralStoreHooks';

const DatabaseStatus = ({title, status}) =>
{   

    const Symbol = ({status}) =>{
    switch (status) {
        case con.LOADING:
            return(<Spin/>)
        case con.OK:
            return(<CheckCircleTwoTone twoToneColor="#52c41a"/>)
        case con.ERROR:
            return(<WarningTwoTone  twoToneColor="#e91822"/>)
        default:
            return(<QuestionCircleOutlined/>);
    }}


    return(
        <div style={{marginLeft : "10px", marginRight : "10px"}}>
            <Title level={5}>{title}</Title>
            <Symbol status={status} />
        </div>
    )

}

function LoadingDatasets() {


    // Participants
    const statusParticipants = RefStoreValue(con.STATUS_DATASET_PARTICIPANTS)

    // Sensors
    const statusSensors = RefStoreValue(con.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const statusSensorPlacement = RefStoreValue(con.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)

    // Wearables
    const statusWearables = RefStoreValue(con.STATUS_DATASET_WEARABLES)

    // Wearable Placement
    const statusWearablesPlacement = RefStoreValue(con.STATUS_DATASET_WEARABLE_PLACEMENT)

    // Phones
    const statusPhones = RefStoreValue(con.STATUS_DATASET_PHONES)

    // Phone Placement
    const statusPhonePlacement = RefStoreValue(con.STATUS_DATASET_PHONE_PLACEMENT)

    // Received Sensor Files
    const statusReceivedSensorFiles = RefStoreValue(con.STATUS_DATASET_RECEIVED_SENSOR_FILES)

    // Received Sensor Files
    const statusIssues = RefStoreValue(con.STATUS_DATASET_ISSUES)

  return (
    <div className='verticalSection'>
        <div className='horizontalSection'>
            <DatabaseStatus title={"Participants"} status={statusParticipants}/> 
            <DatabaseStatus title={"Sensors"} status={statusSensors}/> 
            <DatabaseStatus title={"SensorPlacement"} status={statusSensorPlacement}/> 
            <DatabaseStatus title={"Wearables"} status={statusWearables}/> 
            <DatabaseStatus title={"WearablesPlacement"} status={statusWearablesPlacement}/> 
        </div>
        <div className='horizontalSection'>    
            <DatabaseStatus title={"Phones"} status={statusPhones}/> 
            <DatabaseStatus title={"PhonePlacement"} status={statusPhonePlacement}/> 
            <DatabaseStatus title={"ReceivedSensorFiles"} status={statusReceivedSensorFiles}/> 
            <DatabaseStatus title={"Issues"} status={statusIssues}/> 
        </div>
    </div>
  )
}

export default LoadingDatasets