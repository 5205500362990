// General functions
import { formatDistanceGivenDays } from "./dateFunctions";

export const arrayToDict = (array, idCol) => {
  let dictionary = array.reduce((acc, obj) => {
    acc[obj[idCol]] = obj;
    return acc;
  }, {});

  return dictionary
}

export const getColorOfExpiry = (days) => {
  if (days <= 0)
    return "var(--warning-color-1)"
  if (days <= 4)
    return "#CD5700"
  if (days <= 7)
    return "#E47600"
  if (days < 12)
    return "black"

  return "var(--success-color-2)"
}

export const getColorOfLostDays = (days) => {
  if (days <= 0)
    return "var(--warning-color-1)"

  return "var(--success-color-2)"
}


export const getTextOfExpiry = (days) =>
{

  if(days <= 0)
    return "Now"

  return formatDistanceGivenDays(days)

}

export const getColorOfLastUpload = (days) => {
  if (days >= 20)
    return "var(--warning-color-1)"
  if (days > 16)
    return "#CD5700"
  if (days > 13)
    return "#E47600"
  if (days > 8)
    return "black"

  return "var(--success-color-2)"
}




export const sumArray = (arr) => {

  const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
  return (arr.reduce(sumReducer, 0))
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const filterObjectByKeys = (obj, keys) => {
  return keys.reduce((filteredObj, key) => {
    if (key in obj) {
      filteredObj[key] = obj[key];
    }
    return filteredObj;
  }, {});
};


export const isNumeric = (value) => {
  return typeof value === 'number' && !isNaN(value);
}

export const isNull = (val) => {
  return(val === null || val === undefined || val === "" )
}