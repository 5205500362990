import React, { useEffect } from 'react'
import * as con from "../Constants"

import Title from 'antd/es/typography/Title';
import { StreamQualityChartHook } from '../components/charts/hooks/StreamQualityChartHook';
import streamData from "../data/data_streams_summary.json"


function DashboardDataStreamQuality() {



  // Chart
  const [objectReference, chart] = StreamQualityChartHook({})


  useEffect(() => {

    if(!chart.initialized)
    {
        // Initialize Chart Data
        chart.initialize()       

        // Builds chart Data
        chart.build(streamData[con.SUMMARY_COVERAGE_INTERVALS])
    }

  }, [chart])

  

  return (
    <div className="mainContainer">   
      <Title level={1}>{`Data Stream Quality`}</Title>
      <div style={{minWidth : "98vw", minHeight : "250vh"}}>   
        <svg ref={objectReference}/>
      </div>
      
    </div>


  )
}

export default DashboardDataStreamQuality